import type { ChangeEvent, FC } from 'react'
import type { JobAdStafferType, JobAdType } from '../../../../src/types/jobAds'
import type { EnumJobStafferStatus } from '../../../../src/types/jobs'
import { staffers as staffersSchema } from '../../staffers/qman/staffers'
import StafferProfileModal from './StafferProfileModal'
import { groups } from '../../staffers/qman/groups'

type Props = {
  isOpen: boolean
  onClose: () => void
  stafferId: string
  isProcessingAction?: boolean
  jobAd?: JobAdType
  jobAdStaffer?: JobAdStafferType
  onMessage?: () => void
  onSetFitness?: (
    e: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => void
  onAcceptStaffer?: () => void
  onDenyStaffer?: () => void
  onPagination?: (dir: 'next' | 'prev') => void
  companyNote?: string
  jobStafferStatus?: EnumJobStafferStatus
  groupId?: string
}

const StafferProfileModalWrapper: FC<Props> = ({
  isOpen,
  onClose,
  stafferId,
  onMessage,
  jobAd,
  jobAdStaffer,
  onSetFitness,
  isProcessingAction = false,
  onAcceptStaffer,
  onDenyStaffer,
  onPagination,
  companyNote,
  jobStafferStatus,
  groupId,
}) => {
  const staffer = stafferId ? staffersSchema.get('stafferById', [stafferId]) : null
  const stafferInternalTemp = groupId && stafferId ? groups.get('groupInternalTempByStafferId', [groupId, stafferId]) : null

  if (!staffer) {
    return null
  }

  return (
    <StafferProfileModal
      isOpen={isOpen}
      onClose={onClose}
      staffer={staffer}
      isProcessing={isProcessingAction}
      setFitness={onSetFitness}
      jobAd={jobAd}
      jobAdStaffer={jobAdStaffer}
      onMessage={onMessage}
      onAcceptStaffer={onAcceptStaffer}
      onDenyStaffer={onDenyStaffer}
      onPagination={onPagination}
      companyNote={companyNote}
      jobStafferStatus={jobStafferStatus}
      stafferInternalTemp={stafferInternalTemp}
    />
  )
}

export default StafferProfileModalWrapper
