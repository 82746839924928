import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore'
import type { GroupInternalTempType, GroupJobTypesType, GroupStafferType, GroupType } from '../../../../../src/types/groups'
import type { PermissionsTypeWithId } from '../../../../../src/types/pools'
import {
  getCollectionRef,
  getDataFromCollectionRef,
  getDataFromDocumentRef,
  getDocumentRef,
  getQueryRef,
} from '../wrappers'
import { getPoolsByEmployeeId } from './pools'
import { CollectionReference } from '../../../../../src/types/firebase'
import { InviteType } from '../../../../../src/types/common'

export const getGroupById = (groupId: string) => getDocumentRef<GroupType>('groups', groupId)

// This will return array of all docs (should be just one) which business
// is part of.
export const getGroupsByBusinessId = (businessId: string) =>
  getQueryRef<GroupType>('groups', where('businesses', 'array-contains', businessId))

export const getGroupByIdPromise = async (groupId: string) => getDataFromDocumentRef<GroupType>(getGroupById(groupId))

export const getAllGroups = () => getCollectionRef<GroupType>('groups')

export const getGroupJobTypesById = (groupId: string) =>
  getDocumentRef<GroupJobTypesType>('groups', groupId, 'data', 'customJobTypes')

export const getGroupStaffers = (groupId: string) => getCollectionRef<GroupStafferType>('groups', groupId, 'staffers')

export const getGroupStaffersPermissions = async (groupId: string): Promise<PermissionsTypeWithId[] | null> => {
  const group = await getGroupByIdPromise(groupId)
  if (!group) {
    return null
  }
  const { businesses } = group

  // Fetch staffers permissions for each business separately
  const docsByBus = await Promise.all(
    businesses.map(
      async (businessId: string) =>
        await getDataFromCollectionRef<PermissionsTypeWithId>(
          getQueryRef<PermissionsTypeWithId>('staffersPermissions', orderBy(`businessPermissions.${businessId}`)),
          (item) => ({ ...item.data(), id: item.id })
        )
    )
  )

  // flatten arrays while removing duplicities
  return docsByBus.reduce<PermissionsTypeWithId[]>((uniqDocs, docsOfBus) => {
    docsOfBus?.forEach((doc) => {
      if (!uniqDocs.find(({ id }) => id === doc.id)) {
        uniqDocs.push(doc)
      }
    })
    return uniqDocs
  }, [])
}

export const getGroupStaffer = (groupId: string, stafferId: string) =>
  getDocumentRef('groups', groupId, 'staffers', stafferId)

export const getIsGroupStaffer = async (groupId: string, stafferId: string): Promise<boolean> => {
  const group = await getGroupByIdPromise(groupId)
  if (!group) {
    return false
  }
  const { businesses } = group

  if (businesses && businesses.length > 0) {
    const pools = await getDataFromCollectionRef(getPoolsByEmployeeId(stafferId))
    return !!businesses.some((businessId) => pools?.find((pool) => pool.businessId === businessId))
  }
  return false
}

export const getGroupCustomJobTypes = (groupId: string) => getDocumentRef('groups', groupId, 'data', 'customJobTypes')

export const getGroupsWithBusiness = (businessId: string) =>
  getQueryRef<GroupType[]>('groups', where('businesses', 'array-contains', businessId))

export const getGroupInternalTemps = (groupId: string) => collection(getFirestore(), 'groups', groupId, 'internalTemps') as CollectionReference<GroupInternalTempType>

export const getGroupInternalTempsByBusinessId = (groupId: string, businessId: string) => {
  const collectionRef = getGroupInternalTemps(groupId)
  return query(collectionRef, where('businessId', '==', businessId), where('isDeleted', '==', false))
}

export const getGroupInternalTempByStafferId = (groupId: string, stafferId: string) => 
  getDocumentRef<GroupInternalTempType>('groups', groupId, 'internalTemps', stafferId)

export const getGroupInternalTempInvites = (groupId: string, businessId: string) => {
  const collectionRef = getCollectionRef<InviteType[]>('staffersInvites')
  return query(collectionRef, where('internalTemp', '==', true), where('used', '==', false), where('groupId', '==', groupId), where('businessId', '==', businessId))
}
