import type { InputProps } from '@material-ui/core'
import {
  Box,
  Button,
  Chip,
  Dialog,
  Fab,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Checkbox as MUICheckbox,
  Link as MUILink,
  Select as MUISelect,
  TextField as MUITextField,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Clear as ClearIcon,
  Schedule as ClockIcon,
  CloudUpload as CloudUploadIcon,
  DeleteForever as DeleteForeverIcon,
  Delete as DeleteIcon,
  Help,
  Help as HelpIcon,
  Lock as LockIcon,
  Update as UpdateIcon,
} from '@material-ui/icons'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { Moment } from 'moment'
import * as R from 'ramda'
import type { ChangeEvent, FocusEvent } from 'react'
import React, { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
// @ts-ignore
import { connectFirestore } from 'react-firestore-connect'
// @ts-ignore
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import type { BusinessType } from '../../../../src/types/business'
import type { CountryNameType, InviteType, RegionBasicType } from '../../../../src/types/common'
import type { FreelanceJobFees } from '../../../../src/types/enums'
import type { Firestore, Timestamp } from '../../../../src/types/firebase'
import type { CustomPositionType, GroupType } from '../../../../src/types/groups'
import type { EnumJobType, JobPositionType } from '../../../../src/types/jobTypes'
import type {
  JobDraftType,
  JobTemplateType,
  JobType,
  ShiftType,
  ShiftTypeBasic,
  StafferDetailJobType,
} from '../../../../src/types/jobs'
import type { ManagerTypeWithId } from '../../../../src/types/manager'
import type { PermissionsType, PermissionsTypeWithId, PoolJobType, PoolType } from '../../../../src/types/pools'
import { webUrlBase } from '../../config'
import { TIPS_INFO } from '../../constants'
import { CUSTOM_ADDRESS_BUSINESS_TYPES } from '../../constants/permissions'
import { PATHS } from '../../constants/routes'
import { TooltipDelays } from '../../constants/tooltips'
import { canPostCustomLocationJobs } from '../../helpers'
import { loadGoogleMaps } from '../../helpers/googleMaps'
import { isHoliday } from '../../helpers/holidays'
import { transformPlandayShifts } from '../../helpers/integrations'
import {
  adjustForRoundness,
  costCalculator,
  defaultEventWage,
  getAllJobTypes,
  minEventWage,
  toNetHourlyWageText,
  withSocialCost,
} from '../../helpers/jobs'
import { getCurrencyByBusiness, getCurrencyByCountry } from '../../helpers/regionSelector'
import { getClosestRegionFromCoordinates, getCountryFromAddress } from '../../helpers/regions'
import { formatHoursLong, timeStampFromDate } from '../../helpers/time'
import { errorToast } from '../../helpers/toast'
import type { JobTypeSkill, JobTypesSkills } from '../../staffers/api/JobCostInfo'
import { getHourlyServiceChargeByCountry, JobCostConstants } from '../../staffers/api/JobCostInfo'
import { StaffersJobsAPI, getInternalShifts, jobShiftTagForExternal } from '../../staffers/api/firestore/https/jobs'
import { logBusinessEvent } from '../../staffers/api/firestore/logBusinessEvent'
import {
  getBusinessActivityById,
  getBusinessById,
  getBusinessesByGroupId,
  getPreviousDuties,
} from '../../staffers/api/getters/business.legacy'
import { getFreelanceJobFees, getJobSkills } from '../../staffers/api/getters/common.legacy'
import {
  getGroupById,
  getGroupJobTypesById,
  getGroupStaffersPermissions,
} from '../../staffers/api/getters/groups.legacy'
import { getStaffersByJobId } from '../../staffers/api/getters/jobs.legacy'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import {
  getConnectedPools,
  getPendingInvitedStaffersByPoolId,
  getPoolById,
} from '../../staffers/api/getters/pools.legacy'
import { parseAddressFromDetails } from '../../staffers/api/location'
import moment from '../../util/moment'
import ButtonWithDisabledTooltip from '../ButtonWithDisabledTooltip'
import GroupSelectLocation from '../GroupSelectLocation'
import ManagerSelector from '../ManagerSelector/ManagerSelector'
import Checkbox from '../checkbox/CustomCheckbox'
import Modal from '../mui/Modal'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import type { IntegrationsCtx } from '../providers/IntegrationsProvider'
import type { Performance, Trace } from '../providers/PerfProvider'
import { startTracking } from '../providers/PerfProvider'
import TextField from '../textInputField/TextInputField'
import { confirm } from './ConfirmationModal'
import EditShift from './EditShift'
import JobCostBreakdownModal from './JobCostBreakdownModal'
import JobInvitesExternalModal from './JobInvitesExternalModal'
import JobInvitesInternalModal from './JobInvitesInternalModal'
import InternalShiftImportList from './PushInternalShiftToExternal/InternalShiftImportList/InternalShiftImportList'
import SaveJobTemplate from './SaveJobTemplate'
import { TemplateScheduleModal, selectScheduleStart } from './TemplateScheduleModal'
import styles from './modals.module.css'

export type OptionType = {
  label: string // name of option
  id: string // id of option
  photoUrl?: string // photourl
  stafferPermissions?: PermissionsType // permission doc (only in new search results)
  isAlumni?: boolean
  poolLabel?: string
  isWageSetup?: boolean
}

const {
  MIN_SALARY_HOURLY,
  MIN_POOL_SALARY_HOURLY,
  MIN_HOLIDAY_SALARY_HOURLY,
  MIN_FREELANCE_SALARY_HOURLY,
  SERVICE_CHARGE,
} = JobCostConstants
const INPUT_MAX_SALARY_HOURLY = 999
const INPUT_DEFAULT_SALARY_HOURLY = 170
const MAX_JOB_DURATION_DAYS = 60

const EMPLOYMENT_REASON_TYPES = [
  {
    label: 'Seasonal/extra work',
  },
  {
    label: 'Replacement for another person/s',
  },
]

const UNIFORMS = {
  ALL_BLACK: 'All black', // First one in this object is also default value
  PROVIDED: 'We provide uniform (shirt)',
}
const OTHER_OPTION_KEY = 'OTHER_OPTION'

const MIN_TIME_BEFORE_SHIFT_START = 2
const MIN_TIME_DIFFERENCE = 1
const MIN_TIME_AFTER_NOW = 1

export const getShiftId = (shift: Pick<ShiftTypeBasic, 'timeStart' | 'timeEnd'>): string => {
  const timeStart = moment.utc(moment(shift.timeStart)).startOf('minute')
  const timeEnd = moment.utc(moment(shift.timeEnd)).startOf('minute')
  return timeStart.format('YYYYMMDDHHmm') + timeEnd.format('MMDDHHmm')
}

const isDuplicateShifts = (shiftA: ShiftType, shiftB: ShiftType): boolean => getShiftId(shiftA) === getShiftId(shiftB)

type PoolAccessType = 'PRIVATE' | 'PUBLIC' | 'PUBLIC_SINCE' // these are not actually stored enywhere except the state

type Props = {
  business: BusinessType
  businessId: string
  cancel: () => any
  closeModal: () => any
  connectedPools?: Array<PoolType>
  constants?: FreelanceJobFees
  createFreelanceJob: boolean
  customPositions: { id: 'customJobTypes'; values: CustomPositionType[] } | null
  group: GroupType | null
  groupStaffersPermissions: PermissionsTypeWithId[] | null
  hideBackButton: boolean
  invitedStaffersByPools?: Array<Array<InviteType>>
  isAdmin?: boolean
  integrationsCtx: IntegrationsCtx
  job?: PoolJobType
  jobDrafts?: {
    // string is one of job types for generic drafts
    // or job type concatenated with name for named drafts (templates)
    // otherwise it can be ID in the future
    [name: string]: JobDraftType
  }
  jobId?: string
  jobStaffers?: Array<StafferDetailJobType>
  jobTypes: { values: Array<EnumJobType> }
  manager?: ManagerTypeWithId
  managerGroupBusinesses?: BusinessType[]
  managerId?: string
  onSave?: (jobId?: string, isJobAd?: boolean) => any
  pool?: PoolType | null
  poolAccess?: PoolAccessType
  previousDuties?: Record<string, string[]>
  trace?: Trace
  perf: Performance
  internalToExternalShifts?: ShiftType[] | null
  internalToExternalJobType?: string
  internalToExternalCustomLabel?: string
  internalToExternalActivityId?: string
  internalToExternalDescription?: string
  internalJobs: JobType[]
  jobSkills: JobTypesSkills
} & RouteComponentProps

type State = {
  address: {
    city: string
    country: string
    street: string
    postalCode: string
  } | null
  beautifulAddress?: string
  customUniform: string
  description: string
  duties: Array<{ [duty: string]: boolean }>
  employmentReason: string
  fulltimeWanted: boolean
  gmapsLoaded: boolean
  invites: Array<OptionType>
  isCostBreakdownModalOpen: boolean
  isFreelanceJob: boolean
  isInviteModalOpen: boolean
  isTemplateModalOpen: boolean
  isTemplateSelectOpen: boolean
  jobOptions: string[]
  jobType: string
  limitedToInvited?: boolean
  limitedToInvitedUntil: boolean | Timestamp
  location: { lat: number; lng: number } | null
  locationDescription: string
  locationPlaceId: string
  needsLocationUpdate: boolean
  notifyOnlyManager: string[] | null
  poolAccess: PoolAccessType
  publicSince?: Date
  publicSinceError: string
  region: RegionBasicType
  recommendedWageDialog: 'open' | 'dismissed' | 'closed'
  salaryCurrency: string
  salaryHourly: number // with social cost
  salaryHourlyText: string // without social cost (value of the nett text input)
  saveInProgress: boolean
  selectedBusiness?: BusinessType
  selectedTemplate?: string
  shiftIndex?: number
  shiftToEdit?: ShiftTypeBasic | null
  shifts: Array<ShiftType>
  tipsPickupDay: string
  uniform: string
  useCustomAddress: boolean
  usePermanentContractWage: boolean
  isInformationMessage: boolean
  informationMessage: string
  customLabel: string
  next: string
  useCustomLabel: boolean
  importedShifts: Array<string | undefined>
  internalShifts: Array<ShiftTypeBasic & { jobType: string }> | ShiftTypeBasic[] | null
  shiftsLimit: number
  shiftsToUpdate: { [jobId: string]: Array<string> }
  oldWage: number | null
  oldAddress: string
  jobSkill: JobTypeSkill | null
}

// here so it can be used in gdsfp
const checkPublicSince = (date: Date | Timestamp, shifts: Array<ShiftType>) => {
  const checkPlural = (constant: number) => (constant === 1 ? 'hour' : 'hours')

  if (moment(date).isBefore(moment().add(MIN_TIME_AFTER_NOW, 'hour').toDate(), 'minute')) {
    return `The earliest the job can become public is ${MIN_TIME_AFTER_NOW} ${checkPlural(
      MIN_TIME_AFTER_NOW
    )} after its creation`
  }

  if (shifts.length > 0) {
    // check if shift starts within X hours of publicSince
    const shiftCreatedTooClose = shifts.some(
      ({ timeStart }) => moment(timeStart).diff(moment(date), 'minutes') <= MIN_TIME_DIFFERENCE / 60
    )

    if (shiftCreatedTooClose) {
      return `Job must become public at least ${MIN_TIME_DIFFERENCE} ${checkPlural(
        MIN_TIME_DIFFERENCE
      )} before shift start`
    }

    // check if shift starts within X hours now
    const shiftStartingTooSoon = shifts.some(({ timeStart }) =>
      moment(timeStart).isBefore(moment().add(MIN_TIME_BEFORE_SHIFT_START, 'hours').toDate(), 'minute')
    )

    if (shiftStartingTooSoon) {
      return `Shift must become public at least ${MIN_TIME_BEFORE_SHIFT_START} ${checkPlural(
        MIN_TIME_BEFORE_SHIFT_START
      )} before shift start`
    }
  }

  return ''
}

class CreateEditJob extends Component<Props, State> {
  cache:
    | {
        [place_id: string]: {
          details: {
            formatted_address: string
            geometry: {
              location: { lat: number; lng: number } | null
            }
          }
          region: RegionBasicType
        }
      }
    | Record<string, never>

  constructor(props: Props) {
    super(props)
    this.cache = {}

    const { customPositions, group, groupStaffersPermissions } = props

    // Keeps only custom positions where at least 1 staffer has the custom position assigned
    const customPositionsWithAssignedStaffer =
      (customPositions &&
        group &&
        customPositions.values.filter(({ name, isArchived }) => {
          const { businesses } = group
          return (
            !isArchived &&
            groupStaffersPermissions &&
            groupStaffersPermissions.some((stafferPermissions) =>
              Object.entries(stafferPermissions?.businessPermissions || {}).some(
                ([businessId, positions]) => businesses.includes(businessId) && positions.includes(name)
              )
            )
          )
        })) ||
      []

    // custom jobTypes won't be found in a jobDraft
    // initialize an empty jobType
    let jobType = ''
    let customWage: '' | number = ''
    // if an internal job is being posted,
    // the business can only post an internal job with a custom position,
    // therefore we initialize a custom jobType if there is one
    if (props.poolAccess === 'PRIVATE') {
      jobType =
        customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
          ? customPositionsWithAssignedStaffer[0].name
          : ''
      customWage =
        customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
          ? customPositionsWithAssignedStaffer[0].defaultWage
          : ''
    } else {
      // if this isn't an internal job being posted,
      // initialize a default jobType
      jobType = getAllJobTypes(props.jobTypes?.values)[0] || ''
    }

    const jobTypeInfo =
      (customPositions && customPositions.values.find(({ name }) => name === jobType)) ||
      (props.jobTypes?.values.find(
        ({ name, subTypes }) => name === jobType || (subTypes && subTypes.find((subName) => subName === jobType))
      ) as JobPositionType | CustomPositionType)

    const region = props.job?.region || props.business?.region || ({ countryName: 'Norway' } as RegionBasicType)

    const salaryHourly =
      customWage || props.createFreelanceJob
        ? withSocialCost(defaultEventWage(jobTypeInfo, region), region?.countryName ?? 'Norway')
        : MIN_SALARY_HOURLY

    this.state = {
      address: props.job?.address || props.business.address || null,
      beautifulAddress: undefined,
      customUniform: '',
      description: props.internalToExternalDescription ? props.internalToExternalDescription : '',
      duties: [],
      employmentReason: '',
      fulltimeWanted: false,
      gmapsLoaded: false,
      invites: props.jobStaffers
        ? props.jobStaffers
            .filter(
              ({ id, isInvited, shifts: jobStafferShifts }) =>
                props.job &&
                (props.job.staffersPending.includes(id) || props.job.staffersConfirmed.includes(id) || isInvited) &&
                jobStafferShifts &&
                jobStafferShifts.every((jobStafferShift) => jobStafferShift.status === 'accepted')
            )
            .map(({ stafferInfo, id }) => ({
              id,
              label: `${stafferInfo.nameFirst} ${stafferInfo.nameLast}`,
              poolLabel:
                (
                  (props.connectedPools || []).find(
                    (pool) => pool.employees.includes(id) || pool.staffers.includes(id)
                  ) || {}
                ).name || '',
              photoUrl: stafferInfo.photoUrl,
              isAlumni: false, // TODO @Draho fetch from permissions?
            }))
        : [],
      isFreelanceJob: props.createFreelanceJob,
      isCostBreakdownModalOpen: false,
      isInviteModalOpen: false,
      isTemplateModalOpen: false,
      isTemplateSelectOpen: false,
      jobOptions: [],
      // - do not copy from job document
      limitedToInvitedUntil: false,
      location: null,
      locationDescription: '',
      locationPlaceId: '',
      needsLocationUpdate: false,
      notifyOnlyManager: props.managerId ? [props.managerId] : null,
      poolAccess: props.poolAccess || 'PUBLIC',
      publicSince: undefined,
      publicSinceError: '',
      // initializing jobType here bugs the web app and says you need to provide employment reason
      // which you cannot type, essentially soft-locking you from posting jobs
      // in general this component is super shit and badly needs a refactor
      jobType: '',
      region,
      recommendedWageDialog: 'closed',
      salaryCurrency: props.job?.region
        ? getCurrencyByCountry(props.job.region?.countryName ?? 'Norway')
        : getCurrencyByBusiness(props.business),
      salaryHourly,
      salaryHourlyText: toNetHourlyWageText(salaryHourly, props.createFreelanceJob, false, region?.countryName),
      saveInProgress: false,
      selectedBusiness: undefined,
      selectedTemplate: '',
      shiftToEdit: undefined,
      shifts: props.internalToExternalShifts ? props.internalToExternalShifts : [],
      tipsPickupDay: TIPS_INFO.NO_TIPS,
      uniform: Object.keys(UNIFORMS)[0],
      useCustomAddress: props.job
        ? props.job.useCustomAddress || false
        : CUSTOM_ADDRESS_BUSINESS_TYPES.includes(props.business.businessType) || false,
      usePermanentContractWage: this.props.job?.usePermanentContractWage || false,
      isInformationMessage: !!this.props.job?.informationMessage || false,
      informationMessage: this.props.job?.informationMessage || '',
      customLabel: this.props.internalToExternalCustomLabel
        ? this.props.internalToExternalCustomLabel
        : this.props.job?.customLabel || '',
      useCustomLabel: this.props.internalToExternalCustomLabel ? true : !!this.props.job?.customLabel || false,
      importedShifts: props.internalToExternalShifts ? [props.internalToExternalShifts[0].shiftId] : [],
      internalShifts: null,
      shiftsToUpdate: {},
      next: '',
      shiftsLimit: 5,
      oldWage: null,
      oldAddress: '',
      jobSkill: null,
    }
  }

  async componentDidMount() {
    const { trace, integrationsCtx, job, poolAccess } = this.props
    const { needsLocationUpdate, locationPlaceId } = this.state
    loadGoogleMaps(() => {
      this.setState(
        {
          gmapsLoaded: true,
        },
        async () => {
          if (needsLocationUpdate || (job && job.placeId !== locationPlaceId)) {
            await this.onLocationChange({ place_id: job?.placeId || locationPlaceId }, true)
          }
        }
      )
    })
    trace?.stop()

    // InternalShiftImportList Shifts
    // Pool Access: Internal
    if (poolAccess === 'PRIVATE') {
      const plandayIntegration = integrationsCtx.list?.find(({ name }) => name === 'planday')
      if (plandayIntegration?.installed && plandayIntegration?.logged) {
        const { results: plandayShifts, next } = await integrationsCtx.get('integrations/planday/shifts')
        const transformedPlandayShifts = transformPlandayShifts(Object.values(plandayShifts) || [])
        this.setState({
          internalShifts: [...(transformedPlandayShifts || [])],
          next: next || '',
        })
      }
      // Pool Access: External
    } else {
      if (this.props.manager) {
        const internalShifts =
          this.props.pool && ((await getInternalShifts()) as Array<ShiftTypeBasic & { jobType: string; jobId: string }>)
        const plandayIntegration = integrationsCtx.list?.find(({ name }) => name === 'planday')
        if (plandayIntegration?.installed && plandayIntegration?.logged) {
          const { results: plandayShifts, next } = await integrationsCtx.get('integrations/planday/shifts')
          const transformedPlandayShifts = transformPlandayShifts(plandayShifts || [])
          this.setState({
            internalShifts: [...(internalShifts || []), ...(transformedPlandayShifts || [])],
            next: next || '',
          })
        } else {
          this.setState({
            internalShifts: [...(internalShifts || [])],
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { jobStaffers, connectedPools, job, business } = this.props
    const { useCustomAddress } = this.state

    if (useCustomAddress && !canPostCustomLocationJobs(business)) {
      // @ts-ignore - correct warning but in need of refactor anyways
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          useCustomAddress: false,
        },
        this.reAdjustMinSalary
      )
    }
    // Sometimes not all jobStaffers part are fetched at once
    // - didn't find a better way to solve missing invites without using componentDidUpdate
    if (prevProps.jobStaffers && jobStaffers && jobStaffers.length !== prevProps.jobStaffers.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        invites: jobStaffers
          .filter(
            ({ id, isInvited, shifts: jobStafferShifts }) =>
              job &&
              (job.staffersPending.includes(id) || job.staffersConfirmed.includes(id) || isInvited) &&
              jobStafferShifts &&
              jobStafferShifts.every((jobStafferShift) => jobStafferShift.status === 'accepted')
          )
          .map(({ stafferInfo, id }) => ({
            id,
            label: `${stafferInfo.nameFirst} ${stafferInfo.nameLast}`,
            photoUrl: stafferInfo.photoUrl,
            isAlumni: false, // TODO @Draho fetch from permissions?
            poolLabel:
              connectedPools &&
              (connectedPools.find((pool) => pool.employees.includes(id) || pool.staffers.includes(id)) || {}).name,
          })),
      })
    }
  }

  static getDerivedStateFromProps = (props: Props, prevState: State) => {
    const {
      business,
      connectedPools,
      createFreelanceJob,
      customPositions,
      group,
      groupStaffersPermissions,
      job,
      jobDrafts,
      jobId,
      jobStaffers,
      jobTypes,
      managerId,
    } = props

    const { useCustomAddress } = prevState

    // Keeps only custom positions where at least 1 staffer has the custom position assigned
    const customPositionsWithAssignedStaffer =
      (customPositions &&
        group &&
        customPositions.values.filter(({ name, isArchived }) => {
          const { businesses } = group
          return (
            !isArchived &&
            groupStaffersPermissions &&
            groupStaffersPermissions.some((stafferPermissions) =>
              Object.entries(stafferPermissions.businessPermissions || {}).some(
                ([businessId, positions]) =>
                  // $FlowFixMe - For some reason positions gets enforced as mixed instead of string[]
                  businesses.includes(businessId) && positions.includes(name)
              )
            )
          )
        })) ||
      []

    if (jobTypes && jobTypes.values && !prevState.jobType && business && jobStaffers !== undefined) {
      if (job) {
        const {
          address,
          coordinates,
          description,
          duties,
          employmentReason,
          fulltimeWanted,
          isFreelanceJob,
          isPublic,
          jobType,
          limitedToInvitedUntil,
          location,
          notifyOnlyManager,
          options,
          placeId,
          publicSince,
          salaryCurrency,
          salaryHourly,
          shifts,
          tipsPickupDay,
          uniform: jobUniform,
          usePermanentContractWage,
        } = job

        const isPrivate = isPublic === false // explicitly === false, undef can be public (legacy)
        const poolAccess: PoolAccessType = (publicSince && 'PUBLIC_SINCE') || (isPrivate && 'PRIVATE') || 'PUBLIC'

        const tipsEdited = tipsPickupDay === TIPS_INFO.PAID_WITH_SALARY ? TIPS_INFO.PAID_WITH_SALARY : TIPS_INFO.NO_TIPS

        const locationObject =
          useCustomAddress && location
            ? {
                beautifulAddress: location || '',
                locationDescription: location || '',
                location: { lat: coordinates.latitude, lng: coordinates.longitude },
                locationPlaceId: placeId || '',
              }
            : {}

        // get uniform from the first shift (legacy edit)
        const { uniform: shiftUniform } = shifts[0]
        const uniform = jobUniform || shiftUniform || ''

        const currency = salaryCurrency || getCurrencyByBusiness(business)
        const region = job.region || prevState.selectedBusiness?.region || business.region

        return {
          address,
          description,
          duties: duties ? duties.map((duty) => ({ [duty]: true })) : [],
          employmentReason: employmentReason || EMPLOYMENT_REASON_TYPES[0].label,
          fulltimeWanted,
          isFreelanceJob,
          invites: jobStaffers
            ? jobStaffers
                // sometimes not all jobStaffers are fetched at once - add the rest in the cdu method
                .filter(
                  ({ id, isInvited, shifts: jobStafferShifts }) =>
                    job &&
                    (job.staffersPending.includes(id) || job.staffersConfirmed.includes(id) || isInvited) &&
                    jobStafferShifts &&
                    jobStafferShifts.every((jobStafferShift) => jobStafferShift.status === 'accepted')
                )
                .map(({ stafferInfo, id }) => ({
                  id,
                  label: `${stafferInfo.nameFirst} ${stafferInfo.nameLast}`,
                  photoUrl: stafferInfo.photoUrl,
                  isAlumni: false, // TODO @Draho fetch from permissions?
                  poolLabel:
                    connectedPools &&
                    (connectedPools.find((pool) => pool.employees.includes(id) || pool.staffers.includes(id)) || {})
                      .name,
                }))
            : [],
          limitedToInvitedUntil:
            limitedToInvitedUntil && moment(limitedToInvitedUntil).isValid()
              ? moment(limitedToInvitedUntil).toDate()
              : limitedToInvitedUntil,
          jobType,
          jobOptions: options,
          notifyOnlyManager: typeof notifyOnlyManager === 'string' ? [notifyOnlyManager] : notifyOnlyManager,
          region,
          salaryCurrency: currency,
          salaryHourly,
          salaryHourlyText: toNetHourlyWageText(salaryHourly, isFreelanceJob, true, region?.countryName ?? 'Norway'),
          shifts,
          tipsPickupDay: tipsEdited,
          useCustomAddress,
          poolAccess,
          selectedBusiness: prevState.selectedBusiness || business,
          publicSince: publicSince || undefined,
          publicSinceError: publicSince ? checkPublicSince(publicSince, shifts) : '',
          uniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? uniform : OTHER_OPTION_KEY,
          customUniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? '' : uniform,
          usePermanentContractWage,
          ...locationObject,
        }
      }
      if (jobDrafts) {
        // custom jobTypes won't be found in a jobDraft
        // initialize an empty jobType
        let jobType = ''
        let customWage = null
        // if an internal job is being posted
        // the business can only post an internal job with a custom position,
        // therefore we initialize a custom jobType if there is one
        if (props.poolAccess === 'PRIVATE') {
          jobType =
            customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
              ? customPositionsWithAssignedStaffer[0].name
              : ''
          customWage =
            customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
              ? customPositionsWithAssignedStaffer[0].defaultWage
              : ''
        } else {
          // if this isn't an internal job being
          // initialize a default jobType
          jobType = getAllJobTypes(props.jobTypes?.values)[0] || ''
        }
        const jobDraft = jobDrafts[encodeURIComponent(jobType)]
        if (jobDraft) {
          const {
            description,
            duties,
            employmentReason,
            fulltimeWanted,
            isFreelanceJob: isFreelanceDraft,
            options,
            region,
            salaryCurrency,
            salaryHourly,
            tipsPickupDay,
            uniform,
          } = jobDraft

          const isCustomLocationBusinessType = CUSTOM_ADDRESS_BUSINESS_TYPES.includes(business.businessType)

          const tipsEdited =
            tipsPickupDay === TIPS_INFO.PAID_WITH_SALARY ? TIPS_INFO.PAID_WITH_SALARY : TIPS_INFO.NO_TIPS

          const adjustedSalaryHourly =
            // only consider draft if is or isnt also a freelance job
            (isFreelanceDraft !== createFreelanceJob &&
              (createFreelanceJob ? adjustForRoundness(MIN_FREELANCE_SALARY_HOURLY) : MIN_SALARY_HOURLY)) ||
            (createFreelanceJob
              ? adjustForRoundness(Math.max(salaryHourly, MIN_FREELANCE_SALARY_HOURLY))
              : salaryHourly)

          const currency = salaryCurrency || getCurrencyByBusiness(business)

          return {
            description,
            duties: duties ? duties.map((duty) => ({ [duty]: true })) : [],
            employmentReason: employmentReason || EMPLOYMENT_REASON_TYPES[0].label,
            fulltimeWanted,
            jobType,
            jobOptions: options,
            needsLocationUpdate: true,
            selectedBusiness: prevState.selectedBusiness || business,
            notifyOnlyManager: prevState.notifyOnlyManager,
            region: isCustomLocationBusinessType ? region : business.region,
            salaryCurrency: currency,
            salaryHourly: Number(customWage) || adjustedSalaryHourly,
            salaryHourlyText: toNetHourlyWageText(Number(customWage) || adjustedSalaryHourly, createFreelanceJob, true),
            tipsPickupDay: tipsEdited,
            // Uniform might not be present in drafts - don't change state if it is not
            ...(uniform
              ? {
                  customUniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? '' : uniform,
                  uniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? uniform : OTHER_OPTION_KEY,
                }
              : {}),
          }
        }
      }
      if (!jobId) {
        // initialize an empty jobType
        let jobType = ''
        let customWage = null
        // if an internal job is being posted,
        // the business can only post an internal job with a custom position,
        // therefore we initialize a custom jobType if there is one
        if (props.poolAccess === 'PRIVATE') {
          jobType =
            customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
              ? customPositionsWithAssignedStaffer[0].name
              : ''
          customWage =
            customPositionsWithAssignedStaffer && customPositionsWithAssignedStaffer.length > 0
              ? customPositionsWithAssignedStaffer[0].defaultWage
              : ''
        } else {
          // if this isn't an internal job being posted,
          // initialize a default jobType
          jobType = getAllJobTypes(props.jobTypes?.values)[0] || ''
        }

        const region = prevState.selectedBusiness?.region || business.region

        const jobTypeInfo =
          (customPositions && customPositions.values.find(({ name }) => name === jobType)) ||
          (props.jobTypes?.values.find(
            ({ name, subTypes }) => name === jobType || (subTypes && subTypes.find((subName) => subName === jobType))
          ) as JobPositionType | CustomPositionType)

        const salaryHourly =
          customWage || props.createFreelanceJob
            ? withSocialCost(defaultEventWage(jobTypeInfo, region ?? { countryName: 'Norway' }), region?.countryName)
            : MIN_SALARY_HOURLY

        return {
          isFreelanceJob: createFreelanceJob,
          jobType,
          selectedBusiness: prevState.selectedBusiness || business,
          salaryCurrency: getCurrencyByBusiness(business),
          salaryHourly,
          salaryHourlyText: toNetHourlyWageText(salaryHourly, props.createFreelanceJob, false, region?.countryName),
          employmentReason: EMPLOYMENT_REASON_TYPES[0].label,
          useCustomAddress: canPostCustomLocationJobs(business),
          notifyOnlyManager: managerId ? [managerId] : null,
        }
      }
    }
    return null
  }

  // eslint-disable-next-line class-methods-use-this
  hasJobPermission = (
    invite: OptionType,
    jobType: string,
    businessId: string,
    toastRemoved = false,
    usePublicPermissions = false
  ) => {
    const { stafferPermissions, label } = invite
    const result = !!(stafferPermissions?.businessPermissions && stafferPermissions.businessPermissions[businessId]
      ? stafferPermissions.businessPermissions[businessId].includes(jobType) ||
        (usePublicPermissions && stafferPermissions.positions && stafferPermissions.positions.includes(jobType))
      : stafferPermissions?.positions?.includes(jobType))
    if (toastRemoved && !result) {
      toast.info(`Removing employee ${label}. Does not have permissions for job: ${jobType}`)
    }
    return result
  }

  selectOption = (option: OptionType) =>
    this.setState((prevState) => ({
      invites: [...prevState.invites, option],
    }))

  removeOption = (removeId: string) =>
    this.setState((prevState) => ({
      invites: prevState.invites.filter((option) => option.id !== removeId),
    }))

  toggleDuty = (index: number) =>
    this.setState((prevState) => ({
      duties: R.update(
        index,
        R.map((value) => !value, prevState.duties[index]),
        prevState.duties
      ),
    }))

  renameDuty = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target
    this.setState((prevState) => ({
      duties: R.update(index, { [value]: R.values(prevState.duties[index])[0] }, prevState.duties),
    }))
  }

  addDuty = () =>
    this.setState((prevState) => ({
      duties: [...prevState.duties, { '': true }],
    }))

  removeDuty = (index: number) =>
    this.setState((prevState) => ({
      duties: R.remove(index, 1, prevState.duties),
    }))

  usePreviousDuties = () => {
    const { previousDuties } = this.props
    const { jobType } = this.state
    this.setState({
      duties: ((previousDuties || {})[jobType] || []).map((duty) => ({ [duty]: true })),
    })
  }

  changePoolAccess = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const poolAccess = value as PoolAccessType
    // Default public since on day after so we don't throw error right away
    this.setState(({ publicSince }) => ({
      poolAccess,
      publicSince: {
        PUBLIC: publicSince || moment().toDate(),
        PUBLIC_SINCE: moment().add(1, 'day').add(1, 'hour').toDate(),
        PRIVATE: undefined,
      }[poolAccess],
    }))
  }

  changePublicSince = (date: Moment) => {
    this.setState((prevState) => ({
      publicSince: date.toDate(),
      publicSinceError: checkPublicSince(date.toDate(), prevState.shifts),
    }))
  }

  togglelimitedToInvitedUntil = () =>
    this.setState((prevState) => ({
      limitedToInvitedUntil: !prevState.limitedToInvitedUntil,
    }))

  toggleInviteModal = async () => {
    const { job, poolAccess } = this.props
    const { isInviteModalOpen } = this.state
    if (!isInviteModalOpen && job && job.limitedToInvited === false && poolAccess === 'PRIVATE') {
      const ok = await confirm(
        `This job is already available to all ${job.jobType} employees in your internal network. Continue?`,
        ['Creating new invites will cause it to be available only to the invited employees.']
      )
      if (!ok) {
        return
      }
    }
    this.setState((prevState) => ({
      isInviteModalOpen: !prevState.isInviteModalOpen,
    }))
  }

  toggleTemplateModal = () => {
    this.setState(({ isTemplateModalOpen }) => ({
      isTemplateModalOpen: !isTemplateModalOpen,
    }))
  }

  // returns array of named drafts of current jobType
  getTemplates = (): JobTemplateType[] => {
    const { jobDrafts } = this.props
    const { jobType } = this.state
    return (Object.values(jobDrafts || {}) as JobTemplateType[]).filter(
      (temp) => temp.jobType === jobType && !!temp.templateName
    )
  }

  getTemplate = (jobType: string, templateName: string): JobTemplateType | undefined => {
    const { jobDrafts } = this.props
    if (!jobDrafts || !templateName) {
      return undefined
    }
    const templateId = `${encodeURIComponent(jobType)}@${encodeURIComponent(templateName)}`
    return jobDrafts[templateId] as JobTemplateType
  }

  // return array of shifts created from the shedule of the job template
  // eslint-disable-next-line class-methods-use-this
  shiftsFromTemplateSchedule = async (
    schedule: Array<{
      availableWeek: number
      weekDay: number
      timeStart: string
      timeEnd: string
      positions?: number
      breakDuration?: number
    }>
  ): Promise<ShiftType[]> => {
    const bareShifts = schedule.map(
      ({ availableWeek, weekDay, timeStart, timeEnd, positions = 1, breakDuration = 0 }) => {
        const start = moment(timeStart, 'HH:mm')
        const end = moment(timeEnd, 'HH:mm')
        start.isoWeekday(weekDay)
        end.isoWeekday(weekDay)
        start.isoWeek(start.isoWeek() + availableWeek)
        end.isoWeek(end.isoWeek() + availableWeek)
        if (end.isSameOrBefore(start)) {
          end.add(1, 'day')
        }

        return {
          start,
          end,
          positions,
          breakDuration,
        }
      }
    )

    if (bareShifts.length === 0) {
      return []
    }

    const shiftedShifts = (await selectScheduleStart(bareShifts)) || []

    const shifts = shiftedShifts // ignore shifts shleduled on already passed days
      .filter(({ start }) => start.isAfter(moment().add(1, 'hour')))
      .map(({ start, end, ...rest }) => {
        const shift = {
          timeStart: timeStampFromDate(start.toDate()),
          timeEnd: timeStampFromDate(end.toDate()),
          ...rest,
        }
        return {
          ...shift,
          staffersConfirmed: [],
          staffersPending: [],
          shiftId: getShiftId(shift),
        }
      })

    const ignoredCount = bareShifts.length - shifts.length
    if (ignoredCount > 0) {
      toast.info(`${ignoredCount} shifts from template skipped`)
    }

    return shifts
  }

  // check if job data of the template are same as job data from state
  isTemplateDirty = () => {
    const { selectedTemplate, jobType } = this.state
    const template = selectedTemplate && this.getTemplate(jobType, selectedTemplate)
    if (!template) {
      return false
    }
    const isDirty = false // TODO @Draho

    return isDirty
  }

  // select and apply data from job template
  selectTemplate = async (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const { value } = event.target
    const templateName = String(value)
    const { jobType, shifts: stateShifts, selectedBusiness } = this.state
    const template = templateName && this.getTemplate(jobType, templateName)
    if (!template) {
      this.setState({
        selectedTemplate: '',
      })
      return
    }

    const {
      coordinates,
      description,
      duties,
      employmentReason,
      fulltimeWanted,
      isFreelanceJob,
      location,
      notifyOnlyManager,
      options,
      placeId,
      salaryCurrency,
      salaryHourly,
      schedule,
      tipsPickupDay,
      uniform,
      usePermanentContractWage,
    } = template

    // create shifts from the template schedule
    const useTemplateShifts =
      stateShifts.length === 0
        ? true
        : await confirm('Use shifts from template?', ['Already added shifts will be discarded'])

    const shifts = useTemplateShifts ? await this.shiftsFromTemplateSchedule(schedule || []) : stateShifts

    // update all of the job data state fields from the template
    const useCustomAddress = !!selectedBusiness && canPostCustomLocationJobs(selectedBusiness)

    const locationObject = {
      beautifulAddress: location || '',
      locationDescription: location || '',
      location: location ? { lat: coordinates.latitude, lng: coordinates.longitude } : null,
      locationPlaceId: placeId || '',
    }

    this.setState({
      selectedTemplate: templateName,
      shifts,
      description,
      employmentReason,
      fulltimeWanted,
      isFreelanceJob,
      jobType,
      duties: duties ? duties.map((duty) => ({ [duty]: true })) : [],
      jobOptions: options,
      salaryCurrency,
      salaryHourly,
      tipsPickupDay,
      notifyOnlyManager: typeof notifyOnlyManager === 'string' ? [notifyOnlyManager] : notifyOnlyManager,
      ...locationObject,
      // Uniform might not be present in drafts - don't change state if it is not
      ...(uniform
        ? {
            customUniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? '' : uniform,
            uniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? uniform : OTHER_OPTION_KEY,
          }
        : {
            uniform: '',
            customUniform: '',
          }),
      useCustomAddress,
      usePermanentContractWage: !!usePermanentContractWage,
    })
  }

  saveTemplate = async (templateName: string, calledByUpdateButton?: boolean) => {
    const {
      customUniform,
      description,
      duties: stateDuties,
      employmentReason,
      fulltimeWanted,
      isFreelanceJob,
      jobOptions,
      jobType,
      location,
      locationDescription,
      locationPlaceId,
      notifyOnlyManager,
      salaryCurrency,
      region,
      salaryHourly,
      shifts,
      tipsPickupDay,
      uniform,
      useCustomAddress,
      usePermanentContractWage,
    } = this.state

    const activeDuties: string[] = stateDuties.reduce((result: string[], duty) => {
      const [dutyName, isEnabled] = R.toPairs(duty)[0]
      return isEnabled && dutyName.length > 0 ? [...result, dutyName] : result
    }, [])

    const existingTemplate = this.getTemplate(jobType, templateName)
    if (existingTemplate) {
      const replace = calledByUpdateButton
        ? await confirm(`Do you want to update the ${jobType} template '${templateName}'?`)
        : await confirm(`${jobType} template with name '${templateName}' already exist. Do you want to update it?`)
      if (!replace) {
        return
      }
    }
    this.setState({
      saveInProgress: true,
    })
    try {
      await StaffersJobsAPI.saveJobTemplate(jobType, templateName, {
        placeId: useCustomAddress ? locationPlaceId : '',
        location: useCustomAddress ? locationDescription : '',
        ...(useCustomAddress && location ? { coordinates: location } : {}),
        description,
        duties: activeDuties,
        employmentReason,
        fulltimeWanted,
        isFreelanceJob,
        jobType,
        notifyOnlyManager,
        options: jobOptions,
        region,
        salaryCurrency,
        salaryHourly,
        tipsPickupDay,
        uniform: uniform === OTHER_OPTION_KEY ? customUniform : uniform,
        shifts,
        usePermanentContractWage,
      })
      this.setState({
        isTemplateModalOpen: false,
      })
      toast.success(`Template ${templateName} saved`)
    } catch (e) {
      errorToast('Failed to save the template')
      console.warn(e)
    } finally {
      this.setState({
        saveInProgress: false,
      })
    }
  }

  removeTemplate = async () => {
    const { jobType, selectedTemplate } = this.state

    const templateName = selectedTemplate
    const remove = await confirm(`Do you really want to delete a ${jobType} template with name '${templateName}'?`)
    if (!remove) {
      return
    }
    this.setState({
      saveInProgress: true,
    })
    try {
      await StaffersJobsAPI.removeJobTemplate(jobType, String(templateName))
      this.setState({ selectedTemplate: '' })
      toast.success(`Template ${templateName} removed.`)
    } catch (e) {
      errorToast('Failed to remove the template.')
      console.warn(e)
    } finally {
      this.setState({
        saveInProgress: false,
      })
    }
  }

  switchBusinessAndRedirectToJob = async (jobId: string, shiftId?: string) => {
    const { history, manager } = this.props
    const { selectedBusiness } = this.state
    if (!manager || !selectedBusiness) {
      return
    }
    const db = firebase.firestore()
    const managerRef = getManagerById(db, manager.id)

    try {
      await managerRef.update({
        businessId: selectedBusiness.id,
      })
      // Reset notifications count so app is consistent for this manager
      await firebase.firestore().collection('users').doc(manager.id).update('notifications.unseen', 0)
      history.push({
        pathname: !shiftId ? `/jobs/${jobId}` : `/jobs/${jobId}/${shiftId}`,
      })
    } catch (error) {
      errorToast('Failed to switch to business during redirection, please do so manually')
      console.error((error as Error)?.message ?? error)
    }
  }

  modifySelectedBusinessFields = () => {
    const { business } = this.props
    const { useCustomAddress, selectedBusiness, jobType } = this.state
    /* ----------------------------- 1. Job Location ---------------------------- */
    // If on custom location, we can keep it as it is, otherwise we change it to business location
    if (!useCustomAddress) {
      // When set to '', we will assign the business location in cloud function
      this.setState({
        beautifulAddress: '',
        locationDescription: '',
        locationPlaceId: '',
        location: null,
      })
      toast.info('This job will be posted on the location of the selected business')
      // When changing business, we also need to make sure whether custom location can
      // persist depending on business type
    } else if (useCustomAddress && selectedBusiness && !canPostCustomLocationJobs(selectedBusiness)) {
      this.setState({
        beautifulAddress: '',
        locationDescription: '',
        locationPlaceId: '',
        location: null,
        useCustomAddress: false,
      })
      toast.info('Selected business cannot post jobs at custom locations. Changing to location of business')
    }
    /* ------------- 2. Readjust salary currency to fit the business ------------ */
    this.reAdjustMinSalary()
    if (getCurrencyByBusiness(selectedBusiness) !== getCurrencyByBusiness(business)) {
      toast.info('Changed currency to fit the registered location region')
    }
    /* ------------------------ 3. Check Staffer Invites ------------------------ */
    this.setState((prevState) => ({
      invites: prevState.invites.filter((invite) =>
        this.hasJobPermission(invite, jobType, selectedBusiness?.id || '', true)
      ),
    }))
  }

  onChangeBusiness = async (businessId?: string) => {
    // Incomplete / unselected / removed selected location
    // We don't want to perform anything until manager selects which location to use
    if (!businessId) {
      return
    }

    const { managerGroupBusinesses } = this.props
    const business = (managerGroupBusinesses || []).find((groupBusiness) => groupBusiness.businessId === businessId)
    try {
      if (!business) {
        throw Error(`You are not a manager of business with id: ${businessId}`)
      }
      // We need to update the business which is posting the job for jobPost / jobEdit
      this.setState(
        {
          selectedBusiness: business,
        },
        this.modifySelectedBusinessFields
      )
      // Now we go by individual fields and keep what we can
      toast.success(`Changed location to ${business.businessName}`)
    } catch (error) {
      errorToast((error as Error)?.message ?? 'An error ocured while changing location')
    }
  }

  render() {
    const {
      business,
      businessId,
      cancel,
      closeModal,
      connectedPools,
      customPositions,
      group,
      groupStaffersPermissions,
      hideBackButton,
      invitedStaffersByPools,
      isAdmin,
      job,
      jobId,
      jobStaffers,
      jobTypes,
      manager,
      managerGroupBusinesses,
      managerId,
      pool,
      previousDuties,
      internalToExternalJobType,
    } = this.props

    const {
      beautifulAddress,
      customUniform,
      description,
      duties,
      employmentReason,
      fulltimeWanted,
      gmapsLoaded,
      invites,
      isCostBreakdownModalOpen,
      isFreelanceJob,
      isInviteModalOpen,
      isTemplateModalOpen,
      isTemplateSelectOpen,
      jobOptions,
      jobType,
      limitedToInvitedUntil,
      location,
      notifyOnlyManager,
      poolAccess,
      publicSince,
      publicSinceError,
      region,
      recommendedWageDialog,
      salaryCurrency,
      salaryHourly,
      salaryHourlyText,
      saveInProgress,
      selectedBusiness,
      selectedTemplate,
      shiftIndex,
      shiftToEdit,
      shifts,
      tipsPickupDay,
      uniform,
      useCustomAddress,
      usePermanentContractWage,
      isInformationMessage,
      informationMessage,
      useCustomLabel,
      internalShifts,
      shiftsLimit,
      jobSkill,
    } = this.state

    const isJobLoaded = jobId && job === undefined
    if (
      isJobLoaded ||
      !jobTypes ||
      saveInProgress ||
      pool === undefined ||
      connectedPools === undefined ||
      jobStaffers === undefined ||
      (business.groupId &&
        (group === undefined ||
          managerGroupBusinesses === undefined ||
          customPositions === undefined ||
          groupStaffersPermissions === undefined ||
          invitedStaffersByPools === undefined))
    ) {
      return (
        <Box m={4} display="flex" justifyContent="center">
          <BeatLoader />
        </Box>
      )
    }
    // Keeps only custom positions where at least 1 staffer has the custom position assigned
    const customPositionsWithAssignedStaffer =
      (customPositions &&
        group &&
        customPositions.values.filter(({ name, isArchived }) => {
          const { businesses } = group
          return (
            !isArchived &&
            (groupStaffersPermissions?.some((stafferPermissions) =>
              Object.entries(stafferPermissions.businessPermissions || {}).some(
                ([bId, positions]) => businesses.includes(bId) && positions.includes(name)
              )
            ) ||
              invitedStaffersByPools?.some((invitedStaffers) =>
                invitedStaffers?.some(({ positions }) => positions?.includes(name))
              ))
          )
        })) ||
      []

    const isHolidayJob = this.isHolidayJob()
    // combine holiday shifts with regular day shifts is forbidden
    const partiallyHoliday =
      !isHolidayJob && isFreelanceJob && shifts.some((shift) => isHoliday(shift.timeStart) || isHoliday(shift.timeEnd))

    const jobTypeInfo =
      poolAccess === 'PRIVATE'
        ? customPositions?.values.find(({ name }) => name === jobType)
        : jobTypes?.values.find(
            ({ name, subTypes }) => name === jobType || subTypes?.find((subName) => subName === jobType)
          )

    const currentMinimalSalary =
      isFreelanceJob && (!isHolidayJob || business.region?.countryName === 'Sweden')
        ? adjustForRoundness(jobTypeInfo?.freelanceWage || MIN_FREELANCE_SALARY_HOURLY)
        : (isHolidayJob && MIN_HOLIDAY_SALARY_HOURLY) ||
          jobTypeInfo?.wage ||
          jobTypeInfo?.defaultWage ||
          (poolAccess === 'PRIVATE' ? MIN_POOL_SALARY_HOURLY : MIN_SALARY_HOURLY)

    const hasEmploymentReason = employmentReason && employmentReason.length > 0
    const isAboveMinimumWage = salaryHourly >= currentMinimalSalary

    // Maximum duration between first and last shift
    const isHasTooLongJobDuration =
      shifts.length > 0 &&
      moment(shifts[shifts.length - 1].timeStart).diff(moment(shifts[0].timeStart), 'days') >= MAX_JOB_DURATION_DAYS

    const isHasValidShifts = shifts.length > 0 && !isHasTooLongJobDuration

    const uniformsOptions = [
      ...Object.entries(UNIFORMS).map(([value, label]) => ({ value, label })),
      {
        value: OTHER_OPTION_KEY,
        label: 'Other',
      },
    ]

    // Manager can post job on custom location either if
    // 1. business has specific business type
    // 2. If custom address was already set
    // Previously also for internal job, but that was later removed (https://trello.com/c/eekog0Vu/3568-as-i-am-registered-as-a-hotel-i-should-not-have-the-option-to-choose-a-custom-location-on-a-internal-job)
    const canBeOnCustomLocation =
      (!!selectedBusiness && canPostCustomLocationJobs(selectedBusiness)) ||
      (job && !!job.location && job.location.length > 0)

    const hasSelectedManagersToNotify = notifyOnlyManager === null || notifyOnlyManager.length > 0

    const hasValidAddress = !useCustomAddress || !!location

    const isFormValid =
      !!jobType &&
      isHasValidShifts &&
      isAboveMinimumWage &&
      hasEmploymentReason &&
      (!isFreelanceJob || hasValidAddress || !canBeOnCustomLocation) &&
      (!partiallyHoliday || (partiallyHoliday && poolAccess === 'PRIVATE')) &&
      !!poolAccess &&
      hasSelectedManagersToNotify

    const invalidFormReasons = {
      hasEmploymentReason: 'You must provide an employment reason',
      hasSelectedManagersToNotify: 'You must select at least one manager to be notified',
      hasValidAddress: 'You have to fill valid location address',
      isAboveMinimumWage: 'The provided job wage must be above the minimum wage',
      isHasValidShifts: 'The job must contain at least 1 shift',
      noFreelanceLocation: 'Event job must be provided with a custom location',
      partiallyHoliday: 'The job cannot be partially through holiday and partially through normal day',
      poolAccess: 'You do not have access to posting internal jobs',
      unfinishedBusinessProfile: 'Complete your business profile to save job template',
    }

    const getInvalidFormReason = (): string => {
      if (!isHasValidShifts) {
        return 'isHasValidShifts'
      }
      if (!isAboveMinimumWage) {
        return 'isAboveMinimumWage'
      }
      if (!hasEmploymentReason) {
        return 'hasEmploymentReason'
      }
      if (!(!isFreelanceJob || !!location || !useCustomAddress || !canBeOnCustomLocation)) {
        return 'noFreelanceLocation'
      }
      if (partiallyHoliday && poolAccess !== 'PRIVATE') {
        return 'partiallyHoliday'
      }
      if (!poolAccess) {
        return 'poolAccess'
      }
      if (!hasSelectedManagersToNotify) {
        return 'hasSelectedManagersToNotify'
      }

      if (!(business && business.businessName && business.coordinates)) {
        return 'unfinishedBusinessProfile'
      }

      return 'Unknown reason'
    }

    const beautifulJobTypes = getAllJobTypes(jobTypes?.values)

    const jobTypeOptions = (jobTypeInfo && jobTypeInfo.options) || {}
    const unsortedSelectableOptions = Object.entries(jobTypeOptions).map(([id, name]) => ({
      id,
      name: String(name),
    }))
    const selectableOptions = R.sort(
      (optionA, optionB) => optionA.name.localeCompare(optionB.name),
      unsortedSelectableOptions
    )

    const hasFilledPositions = !!(job && job.positionsFilled > 0) || false
    if (shiftToEdit !== undefined) {
      return (
        <EditShift
          cancel={this.closeShiftEdit}
          closeModal={closeModal}
          initialShift={shiftToEdit as ShiftType | null}
          isPrivate={poolAccess === 'PRIVATE'}
          job={job}
          onSaveShift={shiftIndex === undefined ? this.onAddShift : this.onEditShift(shiftIndex)}
          shiftIndex={shiftIndex || 0}
          shifts={shifts}
          titlePrefix={!jobId ? 'Create Job' : 'Edit Job'}
        />
      )
    }

    // If the business is posting an internal job,
    // find out whether the business has jobTypes to select from for private jobs
    const canSelectJobType = poolAccess === 'PRIVATE' ? !!(customPositionsWithAssignedStaffer.length > 0) : true

    // If the business doesn't have any custom positions assigned to their staffers,
    // they should not have access to the modal at all
    if (!canSelectJobType) {
      return (
        <React.Fragment>
          <ModalHeader close={closeModal} goBack={hideBackButton ? undefined : cancel}>
            {!jobId ? 'Post an Internal Job' : 'Edit a Job'}
          </ModalHeader>
          <Box p={3} className={muiModalStyles.modalContent} textAlign="center">
            <Typography variant="h6" className={styles.textWarning}>
              {selectedBusiness?.groupId
                ? 'You can post an internal job only if you created a custom position and gave access for it to at least one employee.'
                : 'You can post an internal job only if you are a part of a group with custom positions.'}
            </Typography>
            <Box my={2} width={1}>
              {selectedBusiness?.groupId ? (
                <Link to={PATHS.companyPool}>
                  <Button onClick={closeModal} color="secondary" variant="contained">
                    Manage internal employees
                  </Button>
                </Link>
              ) : (
                <Button onClick={closeModal} color="secondary" variant="contained">
                  Close
                </Button>
              )}
            </Box>
          </Box>
        </React.Fragment>
      )
    }
    const needContract = !(group && group.noWorkContracts && poolAccess === 'PRIVATE')

    if (isInviteModalOpen && poolAccess === 'PRIVATE' && pool) {
      return (
        <JobInvitesInternalModal
          businessId={selectedBusiness?.id || businessId}
          business={selectedBusiness || business}
          connectedPools={connectedPools}
          createdJob={{
            businessId,
            description,
            employmentReason,
            isFreelanceJob,
            jobType,
            location,
            options: jobOptions,
            presentStaffers: [...(job?.staffersPending || []), ...(job?.staffersConfirmed || [])],
            salaryCurrency,
            salaryHourly,
            timeEnd: shifts[shifts.length - 1].timeEnd,
            timeStart: shifts[0].timeStart,
            usePermanentContractWage,
          }}
          disabled={!isFormValid || !business}
          disabledReason={getInvalidFormReason()}
          goBack={this.toggleInviteModal}
          hasJobPermission={this.hasJobPermission}
          invalidFormReasons={invalidFormReasons}
          invites={invites}
          job={job}
          jobType={jobType}
          limitedToInvitedUntil={limitedToInvitedUntil}
          needContract={needContract}
          onClose={closeModal}
          onPressSaveJob={this.onPressSaveJob}
          pool={pool}
          removeOption={this.removeOption}
          selectOption={this.selectOption}
          togglelimitedToInvitedUntil={this.togglelimitedToInvitedUntil}
        />
      )
    }

    if (isInviteModalOpen && poolAccess === 'PUBLIC') {
      return (
        <JobInvitesExternalModal
          business={business}
          businessId={businessId}
          createdJob={{
            businessId,
            description,
            employmentReason,
            isFreelanceJob,
            jobType,
            location:
              location?.lat && location.lng ? new firebase.firestore.GeoPoint(location?.lat, location?.lng) : null,
            options: jobOptions,
            presentStaffers: [...(job?.staffersPending || []), ...(job?.staffersConfirmed || [])],
            salaryCurrency,
            salaryHourly,
            timeEnd: shifts[shifts.length - 1].timeEnd,
            timeStart: shifts[0].timeStart,
          }}
          disabled={!isFormValid || !business}
          disabledReason={getInvalidFormReason()}
          connectedPools={connectedPools}
          goBack={this.toggleInviteModal}
          hasJobPermission={this.hasJobPermission}
          invalidFormReasons={invalidFormReasons}
          invites={invites}
          isAdmin={!!isAdmin}
          job={job}
          jobType={jobType}
          limitedToInvitedUntil={limitedToInvitedUntil}
          onClose={closeModal}
          onPressSaveJob={this.onPressSaveJob}
          removeOption={this.removeOption}
          selectOption={this.selectOption}
          togglelimitedToInvitedUntil={this.togglelimitedToInvitedUntil}
          recommendedWageDialog={recommendedWageDialog}
          setDialogClosed={() => this.setState({ recommendedWageDialog: 'closed' })}
          setDialogDismissed={() => this.setState({ recommendedWageDialog: 'dismissed' })}
        />
      )
    }
    const templates = this.getTemplates()
    const currency = getCurrencyByBusiness(selectedBusiness)
    const isCustomLocationDisabled = Array.isArray(job?.staffersConfirmed) && job?.staffersConfirmed.length !== 0

    const groupServiceCharge = group && group.customServiceCharge !== null && group.customServiceCharge !== undefined ? group.customServiceCharge : null
    const businessServiceCharge = selectedBusiness && selectedBusiness.customServiceCharge !== null && selectedBusiness.customServiceCharge !== undefined ? selectedBusiness.customServiceCharge : null
    const customServiceCharge = { group: groupServiceCharge, business: businessServiceCharge }

    return (
      <React.Fragment>
        <ModalHeader close={closeModal} goBack={hideBackButton ? undefined : cancel}>
          {!jobId
            ? `Post ${
                isFreelanceJob ? 'Shifts' : `${poolAccess && poolAccess !== 'PUBLIC' ? 'an Internal Job' : 'Shifts'}`
              }`
            : 'Edit a Job'}
        </ModalHeader>
        <Box id="create-job-window" px={3} className={muiModalStyles.modalContent}>
          <Grid container spacing={3}>
            <Grid item xs={internalShifts !== null && internalShifts.length > 0 ? 8 : 12}>
              <Box pt={3}>
                <Grid container spacing={3}>
                  {(canPostCustomLocationJobs(business) || (!!poolAccess && group && group.businesses.length > 1)) && (
                    <Grid id="location-container" item xs={12}>
                      {/* <h2 className={styles.subtitle}>Location</h2> */}
                      {/* {!!poolAccess && group && group.businesses.length > 1 && manager && selectedBusiness && (
                        <GroupSelectLocation
                          isAdmin={!!this.props.isAdmin}
                          manager={manager}
                          disabled={!!jobId && !!job && !!job.staffersConfirmed.length}
                          groupBusinesses={managerGroupBusinesses || []}
                          onChangeBusiness={this.onChangeBusiness}
                          selectedBusiness={selectedBusiness}
                        />
                      )} */}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* <h1 className={styles.subtitle}>Shifts</h1> */}
                  <Grid container>
                    <Grid item container xs={12} spacing={2}>
                      <Grid id="shifts-wrapper" item xs={12}>
                        {shifts.map(this.renderShiftBadge)}
                      </Grid>
                      {shifts.length > 0 && (
                        <Grid item xs={12}>
                          <Button
                            id="duplicate-shift"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={async () => this.onDuplicateShift(shifts[shifts.length - 1])}
                          >
                            Duplicate shift to next day
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          id="add-shift"
                          size="small"
                          onClick={this.onPressAddShift}
                          startIcon={
                            <Fab color="primary" size="small">
                              <AddIcon />
                            </Fab>
                          }
                        >
                          <Typography variant="overline">Add shift</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                {canBeOnCustomLocation && (
                        <div>
                          <Checkbox
                            title={
                              isFreelanceJob
                                ? 'Does this job have a custom location?'
                                : 'Is this job at a custom location?'
                            }
                            checked={useCustomAddress}
                            onToggleCheckbox={this.onUseCompanyAddressToggle}
                          />
                        </div>
                      )}
                {canBeOnCustomLocation && useCustomAddress && gmapsLoaded && (
                  /* eslint-disable react/jsx-indent */
                  <div>
                    <GooglePlacesAutocomplete
                      error
                      onSelect={this.onLocationChange}
                      initialValue={beautifulAddress}
                      renderInput={(props: InputProps) => (
                        <Input
                          {...props}
                          fullWidth
                          disabled={!isAdmin && isCustomLocationDisabled}
                          onBlur={this.onLocationBlur}
                          autoComplete="shipping street-address"
                        />
                      )}
                    />
                  </div>
                  /* eslint-enable react/jsx-indent */
                )}
                </Grid>
                <Grid item xs={6}>
                      <Checkbox
                        title="Use custom label?"
                        checked={useCustomLabel}
                        onToggleCheckbox={this.toggleUseCustomLabel}
                      />
                      {useCustomLabel && (
                        <MUITextField
                          fullWidth
                          variant="outlined"
                          placeholder="Custom shift name"
                          type="text"
                          name="customLabel"
                          defaultValue={this.state.customLabel}
                          // onBlur={this.onCustomLabelBlur}
                          required={useCustomLabel}
                          inputProps={{ maxLength: 30 }}
                          onChange={(e) => this.onCustomLabelChange(e.target.value)}
                          value={this.state.customLabel}
                        />
                      )}
                    </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <h2 className={styles.subtitle}>Position</h2>
                    {poolAccess === 'PRIVATE' &&
                      group &&
                      customPositionsWithAssignedStaffer &&
                      customPositionsWithAssignedStaffer.length === 0 && (
                        /* eslint-disable react/jsx-indent */
                        <Tooltip
                          leaveDelay={TooltipDelays.WITHOUT_LINKS}
                          title="You can post a job with a given custom position only after you have given a permission for it to at least one employee."
                        >
                          <Help className={styles.tooltipIcon} fontSize="small" />
                        </Tooltip>
                        /* eslint-disable react/jsx-indent */
                      )}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <TextField
                      id="selector-wrapper"
                      className={styles.select}
                      select
                      disabled={hasFilledPositions || (job && job.limitedToInvited)}
                      value={jobType}
                      onChange={this.onJobTypeChange}
                    >
                      {poolAccess === 'PRIVATE' && customPositionsWithAssignedStaffer.length > 0 && (
                        <Box p={1} style={{ background: '#dedede' }}>
                          Group positions:
                        </Box>
                      )}
                      {poolAccess === 'PRIVATE' &&
                        customPositionsWithAssignedStaffer.map(({ name }) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      {poolAccess !== 'PRIVATE' &&
                        beautifulJobTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                    </TextField>
                    {job && job.limitedToInvited && (
                      <Tooltip
                        leaveDelay={TooltipDelays.WITHOUT_LINKS}
                        title="You cannot change job type after job invites have already been sent out."
                      >
                        <Box ml={1}>
                          <LockIcon />
                        </Box>
                      </Tooltip>
                    )}
                    {poolAccess === 'PRIVATE' && (
                      <Tooltip
                        interactive
                        placement="top"
                        leaveDelay={TooltipDelays.WITH_LINKS}
                        title={
                          <Box>
                            <Typography variant="body2">
                              <MUILink
                                href="http://help.staffers.no/en/articles/4923555-how-use-the-create-job-screen"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Click here
                              </MUILink>
                              for more information on how to use the create job screen.
                            </Typography>
                          </Box>
                        }
                      >
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </Box>
                  <Box>
                    {!jobId && (
                      <Box display="flex" alignItems="center">
                        <MUISelect
                          className={styles.select}
                          disabled={hasFilledPositions || (job && job.limitedToInvited) || templates.length === 0}
                          value={selectedTemplate || ''}
                          onChange={this.selectTemplate}
                          onOpen={() => {
                            this.setState({ isTemplateSelectOpen: true })
                          }}
                          onClose={() => {
                            this.setState({ isTemplateSelectOpen: false })
                          }}
                          displayEmpty
                        >
                          {[
                            // nest in array to prevent 'Fragment in Select' error
                            <MenuItem key="NONE" value="">
                              {templates.length === 0 ? (
                                `No templates for ${jobType} saved`
                              ) : (
                                <em>Choose a template</em>
                              )}
                            </MenuItem>,
                            ...templates.map(({ templateName }) => (
                              <MenuItem key={templateName} value={templateName}>
                                <div>
                                  {templateName}
                                  {!isTemplateSelectOpen && this.isTemplateDirty() && <small>&nbsp;(edited)</small>}
                                </div>
                              </MenuItem>
                            )),
                          ]}
                        </MUISelect>
                        {!!selectedTemplate && (
                          <React.Fragment>
                            <Box style={{ marginLeft: '-2px' }} mr={1}>
                              <Tooltip title="Remove template">
                                <IconButton size="small" edge="start" onClick={this.removeTemplate}>
                                  <ClearIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Tooltip title="Update template">
                              <IconButton size="small" onClick={() => this.saveTemplate(selectedTemplate, true)}>
                                <UpdateIcon />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box pt={2}>
                    <Grid container>
                      <Grid item xs container direction="column">
                        <h2 className={styles.subtitle}>
                          Wage per hour
                          {!!isFreelanceJob && <small className={styles.note}>(estimate)</small>}
                        </h2>
                        {!usePermanentContractWage && (
                          <Grid item>
                            <TextField
                              name="wage-per-hour"
                              disabled={
                                !!job?.staffersConfirmed.length ||
                                (usePermanentContractWage && poolAccess !== 'PUBLIC_SINCE')
                              }
                              inputProps={{
                                min: Math.min(
                                  currentMinimalSalary,
                                  poolAccess === 'PRIVATE' ? MIN_POOL_SALARY_HOURLY : MIN_SALARY_HOURLY
                                ),
                              }}
                              type="number"
                              onBlur={() => {
                                this.reAdjustMinSalary()
                              }}
                              onChange={this.onSalaryHourlyTextChange}
                              placeholder={
                                toNetHourlyWageText(salaryHourly, isFreelanceJob, true, region?.countryName) || '0'
                              }
                              value={salaryHourlyText}
                            />
                            {this.isLowerThanRecommended() && (
                              <small className={styles.noteWarn}>lower than recommended wage 220 NOK</small>
                            )}
                          </Grid>
                        )}
                        {!usePermanentContractWage && poolAccess === 'PRIVATE' && (
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                            <span style={{ height: 1, backgroundColor: 'grey', width: 100 }} />
                            <span style={{ marginLeft: 5, marginRight: 5, color: 'grey' }}>OR</span>
                            <span style={{ height: 1, backgroundColor: 'grey', width: 100 }} />
                          </div>
                        )}
                        {!!pool && poolAccess !== 'PUBLIC' && (
                          <Box>
                            <Checkbox
                              title="Offer the same wage as in the permanent contract"
                              checked={usePermanentContractWage}
                              onToggleCheckbox={this.togglePermanentContractWage}
                            />
                          </Box>
                        )}
                      </Grid>
                      {isFreelanceJob && (
                        <Grid item xs container direction="column">
                          <Box display="flex" alignItems="center">
                            <h2 className={styles.subtitle}>Total cost per hour</h2>
                            <Tooltip
                              title="Click for Cost breakdown"
                              onClick={() => {
                                this.setState({ isCostBreakdownModalOpen: true })
                              }}
                            >
                              <Help className={styles.tooltipIcon} fontSize="small" />
                            </Tooltip>
                          </Box>
                          <Grid item>
                            <TextField
                              name="total-cost"
                              className={styles.jobNumberInput}
                              disabled
                              type="number"
                              value={costCalculator({
                                createdAt: job?.createdAt,
                                shifts,
                                region,
                                netSalary: +salaryHourlyText,
                                isFreelanceJob,
                                isPublic: poolAccess === 'PUBLIC',
                                customServiceCharge,
                              }).hourly.total.toFixed(2)}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Grid item>
                    {poolAccess !== 'PRIVATE' && jobSkill && (
                      <div style={{ padding: 10 }} className={styles.noteWarn}>
                        <p>
                          Selecting a salary range of{' '}
                          {jobSkill.skill === 'high'
                            ? `${jobSkill.wageMin}+`
                            : `${jobSkill.wageMin}-${jobSkill.wageMax}`}{' '}
                          {currency} may attract {jobSkill.skill}
                          -skilled {jobType}.
                        </p>
                        <p>
                          However, factors such as seasonality, timing, and last-minute shifts should also be
                          considered.
                        </p>
                      </div>
                    )}
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <h2 className={styles.subtitle}>Uniform</h2>
                      {shifts && shifts[0] && shifts[0].uniform && (
                        <Tooltip
                          title="Uniforms are now selected per job, not per shift. If you want to select a different uniform for different shifts, you will need to create a new job."
                          leaveDelay={TooltipDelays.WITHOUT_LINKS}
                        >
                          <Help fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                    <MUISelect
                      id="uniform-selector"
                      className={styles.selectBusiness}
                      value={uniform}
                      onChange={this.onUniformChange}
                    >
                      {uniformsOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </MUISelect>
                    {uniform === OTHER_OPTION_KEY && (
                      <TextField
                        className={styles.selectBusiness}
                        value={customUniform}
                        placeholder="Please specify"
                        onChange={this.onCustomUniformChange}
                      />
                    )}
                  </Grid>
                  {poolAccess !== 'PRIVATE' && (selectableOptions || []).length > 0 && (
                    <React.Fragment>
                      <h2 className={styles.subtitle}>Requirements/tasks for the job</h2>
                      {selectableOptions.map((option) => (
                        <div key={option.id}>
                          <Checkbox
                            name="requirements-checkbox"
                            title={option.name}
                            checked={this.isJobOptionSelected(option.id)}
                            onToggleCheckbox={this.onToggleJobOption(option.id)}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <h2 className={styles.subtitle}>Additional information</h2>
                  <TextField
                    name="shift-additional-info"
                    className={styles.descriptionTextArea}
                    multiline
                    onChange={this.onDescriptionChange}
                    placeholder="Duties, person to contact..."
                    value={description}
                  />
                  {!isFreelanceJob && (
                    <div>
                      {needContract && (
                        <React.Fragment>
                          <Box display="flex" alignItems="center">
                            <h2 className={styles.subtitle}>Reason for employment</h2>
                            <Tooltip
                              leaveDelay={TooltipDelays.WITHOUT_LINKS}
                              className={styles.tooltipIcon}
                              title={
                                <React.Fragment>
                                  <span>
                                    Legg til årsak for den midlertidige ansettelsen. Dette er for kontraktmessige
                                    grunner.
                                  </span>
                                  <br />
                                  <br />
                                  <span>
                                    Dersom din organisasjon er et og samme AS, kan du se bort ifra dette feltet.
                                  </span>
                                </React.Fragment>
                              }
                            >
                              <HelpIcon />
                            </Tooltip>
                          </Box>
                          <TextField
                            className={styles.selectEmploymentReason}
                            disabled={hasFilledPositions}
                            select
                            value={employmentReason}
                            onChange={this.onEmploymentReasonTypeChange}
                          >
                            {EMPLOYMENT_REASON_TYPES.map(({ label }) => (
                              <MenuItem key={label} value={label}>
                                {label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </React.Fragment>
                      )}
                      {poolAccess === 'PUBLIC' && (
                        <Checkbox
                          title="We are looking for permanent employees in this position"
                          checked={fulltimeWanted}
                          onToggleCheckbox={this.toggleFulltimeWanted}
                        />
                      )}
                    </div>
                  )}
                  {selectedBusiness?.businessType !== 'Other' && !isFreelanceJob && (
                    <React.Fragment>
                      <h2 className={styles.subtitle}>Tips</h2>
                      <Checkbox
                        title={TIPS_INFO.PAID_WITH_SALARY}
                        checked={tipsPickupDay === TIPS_INFO.PAID_WITH_SALARY}
                        onToggleCheckbox={this.onTipsPickupDayChange(TIPS_INFO.PAID_WITH_SALARY)}
                      />
                      <Checkbox
                        title={TIPS_INFO.NO_TIPS}
                        checked={tipsPickupDay === TIPS_INFO.NO_TIPS}
                        onToggleCheckbox={this.onTipsPickupDayChange(TIPS_INFO.NO_TIPS)}
                      />
                    </React.Fragment>
                  )}
                  <Box display="flex" alignItems="center" className={styles.paddingTop}>
                    <h2 className={styles.subtitle}>Job notifications</h2>
                    <Tooltip title="Do you want all managers at your location to be notified about actions on this job, or to only notify selected managers?">
                      <Help className={styles.tooltipIcon} fontSize="small" />
                    </Tooltip>
                  </Box>
                  <Grid>
                    <Checkbox
                      name="notify-all-managers"
                      title="Notify all managers"
                      checked={!notifyOnlyManager}
                      onToggleCheckbox={this.setNotifyManagers(null)}
                    />
                    <Checkbox
                      name="notify-manager"
                      title="Notify selected managers"
                      checked={Array.isArray(notifyOnlyManager)}
                      onToggleCheckbox={this.setNotifyManagers(managerId ? [managerId] : [])}
                    />
                  </Grid>
                  {notifyOnlyManager && (
                    <ManagerSelector
                      id="manager-selector"
                      businessId={(selectedBusiness || {}).id || businessId}
                      selected={typeof notifyOnlyManager === 'string' ? [notifyOnlyManager] : notifyOnlyManager}
                      onSelect={this.selectNotifyManagers}
                      manager={manager}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!!pool && poolAccess === 'PUBLIC_SINCE' && (
                    <Box mb={2}>
                      <KeyboardDateTimePicker
                        label="Job becomes public after"
                        value={publicSince}
                        ampm={false}
                        format="Do MMMM, HH:mm"
                        error={!!publicSinceError}
                        onChange={(date) => this.changePublicSince(date as Moment)}
                        disablePast
                        disabled={poolAccess !== 'PUBLIC_SINCE'}
                        required={poolAccess === 'PUBLIC_SINCE'}
                        minDate={moment().add(1, 'hour').toDate()}
                        minDateMessage="You need to select at least 1 hour after today"
                        maxDate={moment().add(1, 'year').toDate()} // just a fallback if anyone was trying to be funny
                        maxDateMessage="The maximum amount until job becomes internal is 1 year (or just make it permanently internal)"
                      />
                      {!!publicSinceError && <FormHelperText error>{publicSinceError}</FormHelperText>}
                    </Box>
                  )}
                </Grid>
              </Grid>
              {partiallyHoliday && poolAccess !== 'PRIVATE' && (
                <div className={styles.columns}>
                  <span className={styles.textWarning}>
                    You need to post shifts that are on holidays separately from shifts that are not on holidays.
                  </span>
                </div>
              )}
              {isHolidayJob && (
                <div className={styles.columns}>
                  <p className={styles.textWarning}>
                    {business.address?.country === 'Norway' &&
                      `The minimum wage on this day is ${MIN_HOLIDAY_SALARY_HOURLY} ${currency}.
                        You will not have to add any other percentages when you pay out the salary. `}
                    {`Staffers AS charges ${
                      Object.values(SERVICE_CHARGE)[0][(business.address.country ?? 'Norway') as CountryNameType]
                        .holiday
                    } ${currency} per hour as service charge on holidays.`}
                  </p>
                </div>
              )}
              <Grid item xs={12}>
                <div>
                  <Checkbox
                    title="Use pre-written message for confirmed staffers?"
                    checked={isInformationMessage}
                    onToggleCheckbox={this.toggleIsInformationMessage}
                  />
                </div>
                {isInformationMessage && (
                  <TextField
                    name="shift-message"
                    className={styles.descriptionTextArea}
                    multiline
                    onChange={this.onMessageChange}
                    placeholder="Pre-written message for confirmed staffers with information about your job"
                    value={informationMessage}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <h2 className={styles.subtitle}>Tasks</h2>
                {/* Option to import previous duties
              - only if they exist and arent currently any custom one
            */}
                {previousDuties &&
                  previousDuties[jobType] &&
                  previousDuties[jobType].length > 0 &&
                  duties.length < 1 && (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.usePreviousDuties}
                        startIcon={<CloudUploadIcon />}
                      >
                        {`Use previous ${jobType} tasks`}
                      </Button>
                    </Grid>
                  )}
                <Grid item container xs={12} id="tasks-container">
                  {/* Render of individual duties */}
                  {duties.map((dutyObject, index) =>
                    R.toPairs(dutyObject).map(([duty, isEnabled]) => (
                      <Grid item xs={4} spacing={2} key={index}>
                        <Box display="flex" alignItems="center" my={1}>
                          <MUICheckbox
                            color="primary"
                            name={duty}
                            checked={isEnabled}
                            onChange={() => this.toggleDuty(index)}
                          />
                          <MUITextField
                            id="task-input"
                            size="small"
                            name={String(index)}
                            value={duty}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.renameDuty(e, index)}
                            placeholder="New task"
                          />
                          <IconButton aria-label="Remove task" onClick={() => this.removeDuty(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))
                  )}
                </Grid>
                {/* Add new duty */}
                <Grid item xs={12}>
                  <IconButton aria-label="Add task" color="primary" onClick={this.addDuty}>
                    <AddIcon color="primary" />
                  </IconButton>
                  Add task
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={jobId ? 3 : 2}>
                  <Button fullWidth variant="contained" color="default" onClick={cancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={jobId ? 3 : 4}>
                  <Box display="flex" alignItems="center">
                    <ButtonWithDisabledTooltip
                      id="post-shift-btn"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isFormValid || !business || !!publicSinceError}
                      reasons={invalidFormReasons}
                      reason={getInvalidFormReason()}
                      onClick={this.onPressSaveJob}
                    >
                      {jobId
                        ? `Save shift${shifts.length > 1 ? 's' : ''}`
                        : `Post shift${shifts.length > 1 ? 's' : ''}`}
                    </ButtonWithDisabledTooltip>
                    <Box ml={1}>
                      <Tooltip title="Notify all employees with this position">
                        <div>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                {((((connectedPools &&
                  connectedPools.find(
                    (connectedPool) => connectedPool.employees && connectedPool.employees.length > 0
                  )) ||
                  (connectedPools &&
                    connectedPools.find(
                      (connectedPool) => connectedPool.staffers && connectedPool.staffers.length > 0
                    ))) &&
                  poolAccess === 'PRIVATE') ||
                  (poolAccess === 'PUBLIC' && isFreelanceJob)) && (
                  <Grid item xs={jobId ? 3 : 4}>
                    <Box display="flex" alignItems="center">
                      <ButtonWithDisabledTooltip
                        id="offer-job-btn"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!isFormValid || !business || !!publicSinceError}
                        reasons={invalidFormReasons}
                        reason={getInvalidFormReason()}
                        onClick={this.toggleInviteModal}
                      >
                        {jobId ? 'Manage invites' : 'Offer job'}
                      </ButtonWithDisabledTooltip>
                      <Box ml={1}>
                        <Tooltip title="Offer the job directly to one or more selected employee(s)">
                          <div>
                            <HelpIcon />
                          </div>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={jobId ? 3 : 2}>
                  <Box display="flex" alignItems="center">
                    <ButtonWithDisabledTooltip
                      id="save-template-btn"
                      fullWidth
                      variant="contained"
                      disabled={
                        !isFormValid ||
                        !!publicSinceError ||
                        !(business && business.businessName && business.coordinates)
                      }
                      reasons={invalidFormReasons}
                      reason={getInvalidFormReason()}
                      onClick={this.toggleTemplateModal}
                    >
                      Save template
                    </ButtonWithDisabledTooltip>
                    <Box ml={1}>
                      <Tooltip title="When saving templates, they are linked to positions and the day of the week. To find you templates, choose the position, and see the drop down menu that appears. By clicking the drop-down menu, you will see all your saved templates with the name you added. If a drop down menu doesn't appear after choosing position, you have not got any templates saved.">
                        <div>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                {this.canReleaseToInternal() && (
                  <Box py={2}>
                    <Typography variant="caption" className={styles.textWarning}>
                      The shift that was accepted from your first employee will not be offered again after posting this
                      to internal.
                    </Typography>
                  </Box>
                )}
                {this.canReleaseToInternal() && (
                  <Grid item xs>
                    <Box display="flex" alignItems="center">
                      <ButtonWithDisabledTooltip
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={this.releaseToInternal}
                        reasons={invalidFormReasons}
                        reason={getInvalidFormReason()}
                        disabled={false}
                      >
                        Post to internal
                      </ButtonWithDisabledTooltip>
                      <Box ml={1}>
                        <Tooltip title="This job will be posted to all employees in your internal network (except for the shifts which were already accepted).">
                          <div>
                            <HelpIcon />
                          </div>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {hasFilledPositions && (
                <Box py={2}>
                  <Typography variant="caption" className={styles.textWarning}>
                    Some fields are uneditable after accepting a staffer to the job because the contract is signed
                    <ul>
                      <li>Position</li>
                      <li>Wage</li>
                      <li>Reason for employment</li>
                    </ul>
                  </Typography>
                </Box>
              )}
              <Modal isOpen={isTemplateModalOpen} className={muiModalStyles.enforcedWidthModal}>
                <SaveJobTemplate jobType={jobType} onSave={this.saveTemplate} onClose={this.toggleTemplateModal} />
              </Modal>
              <Modal isOpen={isCostBreakdownModalOpen} className={muiModalStyles.enforcedWidthModal}>
                <JobCostBreakdownModal
                  onClose={() => {
                    this.setState({ isCostBreakdownModalOpen: false })
                  }}
                  data={{
                    shifts,
                    netSalary: +salaryHourlyText,
                    region,
                    isFreelanceJob,
                    isPublic: poolAccess === 'PUBLIC',
                    customServiceCharge,
                  }}
                />
              </Modal>
            </Grid>
            {internalShifts !== null && internalShifts.length > 0 && (
              <Grid item xs={4} className={muiModalStyles.internalShifts}>
                <InternalShiftImportList
                  shifts={internalShifts}
                  onImportShift={this.onAddShift}
                  onAddImportedShift={this.onAddImportedShift}
                  importedShifts={shifts.map((shift) => shift.shiftId)}
                  activityJobType={internalToExternalJobType}
                  shiftsLimit={shiftsLimit}
                  onSeeMore={this.onSeeMore}
                  plandayNext={this.state.next}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <TemplateScheduleModal />
        <Dialog
          open={recommendedWageDialog === 'open'}
          onClose={() => this.setState({ recommendedWageDialog: 'closed' })}
        >
          <ModalHeader close={() => this.setState({ recommendedWageDialog: 'closed' })}>Recommended wage</ModalHeader>
          <Box p={2}>
            <Typography>Hey there! 👋</Typography>
            <br />
            <Typography>
              The wage you&apos;ve set for this same-day job posting is below the recommended 220 NOK per hour. 📉 To
              boost your chances of success, consider offering a higher wage. 🚀
            </Typography>{' '}
            <br />
            <Typography>What would you like to do?</Typography>
            <Box py={1} display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ recommendedWageDialog: 'closed' })}
              >
                Go back
              </Button>
              <Box ml={1}>
                <Button
                  onClick={() => this.setState({ recommendedWageDialog: 'dismissed' }, this.onPressSaveJob)}
                  variant="contained"
                >
                  Post anyway
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      </React.Fragment>
    )
  }

  renderShiftBadge = (shift: ShiftType, index: number) => {
    const { timeEnd, timeStart, staffersConfirmed, positions } = shift
    const positionsFilled = staffersConfirmed ? staffersConfirmed.length : 0
    const dateText = moment(timeStart).format('Do of MMM, dddd')
    const timeText = `${moment(timeStart).format('HH:mm')} - ${moment(timeEnd).format('HH:mm')}`
    const durationText = `${formatHoursLong(+moment(timeEnd).diff(moment(timeStart), 'minutes') / 60)}`
    const chipClass =
      (!positionsFilled && muiModalStyles.darkChip) ||
      (positionsFilled > 0 && styles.partiallyFilledChip) ||
      (positionsFilled === positions && '') ||
      ''

    const key = `${dateText}:${timeText}`
    return (
      <Grid
        container
        key={key}
        spacing={1}
        alignItems="center"
        style={
          !positionsFilled ? { marginTop: '-8px', marginBottom: '-8px' } : { marginTop: '2px', marginBottom: '2px' }
        }
      >
        <Grid item xs={6}>
          <Chip
            id="edit-shift"
            color={positionsFilled ? 'primary' : 'default'}
            icon={<ClockIcon className={muiModalStyles.headerIcon} />}
            className={chipClass}
            onClick={this.onPressEditShift(shift, index)}
            label={`${dateText} - ${timeText} (${durationText})`}
          />
        </Grid>
        <Grid item xs={3}>
          <Chip
            id="positions-filled"
            color={positionsFilled ? 'primary' : 'default'}
            className={chipClass}
            label={`Positions filled: ${positionsFilled} out of ${positions}`}
          />
        </Grid>
        {!positionsFilled && (
          <Grid item>
            <Box ml={2}>
              <Button
                id="delete-shift"
                size="small"
                onClick={() => this.removeShift(index, shift)}
                startIcon={
                  <Fab color="default" size="small" className={styles.defaultFab}>
                    <DeleteForeverIcon />
                  </Fab>
                }
              >
                <Typography variant="overline">Remove</Typography>
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  canReleaseToInternal = () => {
    const { job, pool } = this.props

    if (job && job.limitedToInvited && pool) {
      // check whehther it is possible to saturate the shifts by invited staffers
      return job.shifts.some((shift) => shift.positions > shift.staffersPending.length + shift.staffersConfirmed.length)
    }
    return false
  }

  // this will allow any internal staff to apply fot the job
  releaseToInternal = async () => {
    const { job } = this.props
    if (!job) {
      return
    }
    const ok = await confirm(
      `Any ${job.jobType} employee in your internal network will be able to apply for the shifts of this job. Existing invites will remain.`
    )
    if (!ok) {
      return
    }
    this.setState(
      {
        limitedToInvitedUntil: false,
      },
      async () => {
        try {
          await this.onPressSaveJob()
        } catch (err) {
          console.warn(err)
        }
      }
    )
  }

  contractWageChanged = () => {
    const { job } = this.props
    const { usePermanentContractWage } = this.state

    if (usePermanentContractWage !== job?.usePermanentContractWage) {
      return false
    }
    return true
  }

  togglePermanentContractWage = () => {
    const { job } = this.props
    const { usePermanentContractWage } = this.state

    if (job?.staffersConfirmed.length) {
      toast.info('You cannot change the wage after the staffer has been confirmed.')
      return
    }

    this.setState({
      usePermanentContractWage: !usePermanentContractWage,
    })

    if (job && this.contractWageChanged()) {
      const oldSalary = job ? +job.calculatedCost.hourly.salary.toFixed(2) : null
      this.setState({
        oldWage: oldSalary,
      })
    } else {
      this.setState({
        oldWage: null,
      })
    }
  }

  toggleIsInformationMessage = () => {
    this.setState(({ isInformationMessage }) => ({
      isInformationMessage: !isInformationMessage,
    }))
  }

  toggleUseCustomLabel = () => {
    this.setState(({ useCustomLabel }) => ({
      useCustomLabel: !useCustomLabel,
    }))
  }

  onUseCompanyAddressToggle = () => {
    const { selectedBusiness, useCustomAddress } = this.state
    const { business, job, isAdmin } = this.props

    if (!selectedBusiness) {
      return
    }
    const hasConfirmedStaffers = job && Array.isArray(job.staffersConfirmed) && job.staffersConfirmed.length !== 0
    if (!isAdmin && hasConfirmedStaffers) {
      toast.info('You cannot change the address after staffer has been confirmed.')
      return
    }
    const isEventCompany = selectedBusiness.businessType === 'Event company'

    const toOff = useCustomAddress

    if (toOff && isEventCompany) {
      toast.info('Event companies can post only jobs with an unique location.')
      return
    }

    this.setState(({ useCustomAddress }) => ({
      useCustomAddress: !useCustomAddress,
    }))

    this.setState(
      ({ useCustomAddress, region, locationPlaceId }) => ({
        // if we toggle custom address off
        // use business region (since it will be the job region), otherwise use custom region
        region: useCustomAddress ? ((selectedBusiness.region || business.region) as RegionBasicType) : region,
        locationPlaceId: useCustomAddress
          ? job?.placeId || ''
          : selectedBusiness?.placeId || business?.placeId || locationPlaceId,
      }),
      async () => {
        if (toOff) {
          return
        }
        // We need to recalculate google places autocomplete input value
        // and region if (and only if) we toggled custom adddress back on
        await this.onLocationChange({ place_id: this.state.locationPlaceId })
      }
    )
  }

  onLocationChange = async (place: { place_id: string }, isDidMount = false) => {
    const { job } = this.props
    try {
      // Since the component is written in shit way with multiple re-renders
      // we implement a basic caching mechanism to avoid multiple requests
      // and hence rather expensive places API billing
      if (!place.place_id) {
        this.setState({
          address: {
            city: '',
            country: '',
            postalCode: '',
            street: '',
          },
          beautifulAddress: '',
          locationDescription: '',
          location: null,
        })
        return
      }
      if (this.state.gmapsLoaded) {
        const details = this.cache[place.place_id]?.details ?? (await geocodeByPlaceId(place.place_id))[0]
        const address = parseAddressFromDetails(details)

        const getLoc = <T extends number | (() => number)>({
          lat,
          lng,
        }: {
          lat: T
          lng: T
        }): { lat: number; lng: number } =>
          typeof lat === 'function' && typeof lng === 'function'
            ? ({ lat: lat(), lng: lng() } as { lat: number; lng: number })
            : ({ lat, lng } as { lat: number; lng: number })

        if (!details.geometry.location) {
          this.setState({
            locationPlaceId: '',
          })
          return
        }

        const { lat, lng } = getLoc(details.geometry.location)
        const country = getCountryFromAddress(details.formatted_address)

        const region = this.cache[place.place_id]?.region ?? (await getClosestRegionFromCoordinates(lat, lng, country))
        // We cache the result to prevent excessive fetching
        this.cache = {
          ...this.cache,
          [place.place_id]: {
            details,
            region,
          },
        }
        this.setState(
          {
            address,
            beautifulAddress: details ? details.formatted_address : '',
            locationDescription: details ? details.formatted_address : '',
            locationPlaceId: place.place_id,
            location: details ? getLoc(details.geometry.location) : null,
            region,
          },
          this.reAdjustMinSalary
        )
        const oldAddressText = `${job?.address?.street}, ${job?.address?.postalCode} ${job?.address?.city}, ${job?.address?.country}`
        const currentAddressText = `${address?.street}, ${address?.postalCode} ${address?.city}, ${address?.country}`
        if (oldAddressText !== currentAddressText) {
          this.setState({
            oldAddress: oldAddressText,
          })
        }
      }
    } catch (error) {
      // We don't allow for posting jobs outside of available regions
      if (!isDidMount) {
        console.error('location change error', error)
      }
    }
  }

  onCustomLabelChange = (value: string) => {
    const { useCustomLabel } = this.state
    if (useCustomLabel) {
      this.setState({
        customLabel: value,
      })
    }
  }

  onLocationBlur = (event: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    const placeId = this.state.locationPlaceId
    if (value) {
      this.onLocationChange({ place_id: placeId })
    } else {
      this.setState({
        beautifulAddress: '',
        locationDescription: '',
        locationPlaceId: '',
        location: null,
      })
    }
  }

  onCustomLabelBlur = (event: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    if (value) {
      this.onCustomLabelChange(value)
    } else {
      this.setState({
        customLabel: '',
      })
    }
  }

  onUniformChange = (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const { value } = event.target
    this.setState({
      uniform: String(value),
    })
  }

  onCustomUniformChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({
      customUniform: value,
    })
  }

  getDefaultSalary = (jobType: string, isFreelanceJob: boolean): number => {
    const { region } = this.state
    const getDefaultSalaryNoHoliday = () => {
      const { jobTypes, customPositions } = this.props
      if (!jobTypes) {
        return isFreelanceJob ? MIN_FREELANCE_SALARY_HOURLY : INPUT_DEFAULT_SALARY_HOURLY
      }

      const jobTypeInfo =
        (customPositions && customPositions.values.find(({ name }) => name === jobType)) ||
        jobTypes?.values.find(
          ({ name, subTypes }) => name === jobType || (subTypes && subTypes.find((subName) => subName === jobType))
        )
      if (!jobTypeInfo) {
        return isFreelanceJob ? MIN_FREELANCE_SALARY_HOURLY : INPUT_DEFAULT_SALARY_HOURLY
      }

      if (isFreelanceJob) {
        return withSocialCost(
          defaultEventWage(jobTypeInfo, region ?? { countryName: 'Norway' }),
          region?.countryName ?? 'Norway'
        )
      }

      return jobTypeInfo.defaultWage || jobTypeInfo.wage || INPUT_DEFAULT_SALARY_HOURLY
    }

    // we use legacy system for holiday pay in norway
    if (region.countryName === 'Norway') {
      const defaultSalary = this.isHolidayJob()
        ? Math.max(MIN_HOLIDAY_SALARY_HOURLY, getDefaultSalaryNoHoliday())
        : getDefaultSalaryNoHoliday()
      return isFreelanceJob ? adjustForRoundness(defaultSalary) : defaultSalary
    }

    // in other countries we simply use whatever and floor it by min wage
    // service charge is calculated in costCalculator()
    const defaultSalary = getDefaultSalaryNoHoliday()
    return isFreelanceJob ? adjustForRoundness(defaultSalary, region?.countryName ?? 'Norway') : defaultSalary
  }

  onJobTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { businessId, jobDrafts } = this.props
    const { isFreelanceJob, selectedBusiness } = this.state
    const jobType = event.target.value
    if (jobType !== this.state.jobType) {
      const jobDraft = jobDrafts && jobDrafts[encodeURIComponent(jobType)]
      if (jobDraft) {
        const {
          description,
          employmentReason,
          fulltimeWanted,
          options,
          salaryCurrency,
          salaryHourly,
          tipsPickupDay,
          uniform,
        } = jobDraft

        this.setState(
          (prevState: State) => ({
            description,
            employmentReason: employmentReason || EMPLOYMENT_REASON_TYPES[0].label,
            fulltimeWanted,
            invites: prevState.invites.filter((invite) =>
              this.hasJobPermission(invite, jobType, selectedBusiness?.id || businessId, true)
            ),
            jobType,
            jobOptions: options,
            salaryCurrency,
            salaryHourly,
            salaryHourlyText: toNetHourlyWageText(salaryHourly, isFreelanceJob, true),
            tipsPickupDay,
            // Uniform might not be present in drafts - don't change state if it is not
            ...(uniform
              ? {
                  customUniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? '' : uniform,
                  uniform: UNIFORMS[uniform as keyof typeof UNIFORMS] ? uniform : OTHER_OPTION_KEY,
                }
              : {
                  uniform: '',
                  customUniform: '',
                }),
          }),
          () => {
            this.reAdjustMinSalary()
            this.computeStateJobSkill(salaryHourly, jobType)
          }
        )
      } else {
        const defaultSalaryHourly = this.getDefaultSalary(jobType, isFreelanceJob)
        this.setState(
          {
            jobOptions: [],
            jobType,
            salaryHourly: defaultSalaryHourly,
            salaryHourlyText: toNetHourlyWageText(defaultSalaryHourly, isFreelanceJob, true),
          },
          () => {
            this.reAdjustMinSalary()
            this.computeStateJobSkill(defaultSalaryHourly, jobType)
          }
        ) // So we adjust minimal salary incase the jobType is skilled)
      }
    }
  }

  onEmploymentReasonTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const employmentReason = event.target.value
    this.setState({ employmentReason })
  }

  toggleFulltimeWanted = () => {
    this.setState(({ fulltimeWanted }) => ({
      fulltimeWanted: !fulltimeWanted,
    }))
  }

  setNotifyManagers = (value: null | string[]) => () => {
    this.setState(({ notifyOnlyManager }) => ({
      notifyOnlyManager: value ? notifyOnlyManager || value : value,
    }))
  }

  selectNotifyManagers = (values: string[]) => {
    this.setState({
      notifyOnlyManager: values,
    })
  }

  computeStateJobSkill = (salaryHourly: number, jobTypeParam?: string) => {
    const { jobSkills } = this.props
    const jobType = jobTypeParam || this.state.jobType    

    if (jobSkills && jobType && jobSkills[jobType]) {
      const jobSkill = jobSkills[jobType].find(
        (skill) => skill.wageMin <= salaryHourly && skill.wageMax >= salaryHourly
      )

      if (jobSkill) {
        return this.setState({ jobSkill })
      }
    }

    this.setState({ jobSkill: null })
  }

  onSalaryHourlyTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { job } = this.props
    const salaryHourlyText = event.target.value
    const salaryHourly = +parseFloat(salaryHourlyText).toFixed(2)
    const oldSalary = job ? +job.calculatedCost.hourly.salary.toFixed(2) : null

    if (salaryHourlyText === '' || salaryHourly <= INPUT_MAX_SALARY_HOURLY) {
      this.setState({ salaryHourlyText })
    } else {
      this.setState({ salaryHourlyText: INPUT_MAX_SALARY_HOURLY.toString() })
    }

    if (oldSalary && salaryHourly < oldSalary) {
      this.setState({
        oldWage: oldSalary,
      })
    } else {
      this.setState({
        oldWage: null,
      })
    }

    this.computeStateJobSkill(salaryHourly)
  }

  // we only use event object do distinguish that function was not called form onJobTypeChange
  reAdjustMinSalary = () => {
    const { jobTypes, business, customPositions, job } = this.props
    const { jobType, region, poolAccess, salaryHourlyText } = this.state

    const isFreelanceJob = job ? job.isFreelanceJob : !!this.props.createFreelanceJob

    const salaryHourly = isFreelanceJob
      ? withSocialCost(+parseFloat(salaryHourlyText), region?.countryName ?? 'Norway') // salaryHourly is brutto, salaryHourlyText netto
      : +parseFloat(salaryHourlyText)

    // This is a bit of a clusterfuck... but first we attempt to find customPosition wage,
    // if not found we search for direct minimal wage for position type
    // otherwise we return the default subtype wage
    const jobTypeInfo =
      (customPositions && !isFreelanceJob && customPositions.values.find(({ name }) => name === jobType)) ||
      (jobTypes?.values.find(
        ({ name, subTypes }) => name === jobType || (subTypes && subTypes.find((subName) => subName === jobType))
      ) as JobPositionType | CustomPositionType)

    if (!jobTypeInfo) {
      return <BeatLoader />
    }

    const isHolidayJob = this.isHolidayJob()

    const currentMinimalSalary =
      isFreelanceJob && (!isHolidayJob || business.region?.countryName === 'Sweden')
        ? minEventWage(jobTypeInfo, region)
        : (isHolidayJob && MIN_HOLIDAY_SALARY_HOURLY) ||
          (jobTypeInfo && (jobTypeInfo.wage || jobTypeInfo.defaultWage)) ||
          (poolAccess === 'PRIVATE' ? MIN_POOL_SALARY_HOURLY : MIN_SALARY_HOURLY)

    // In case it is a freelance job we need to use brutto values (without social costs)
    // otherwise we keep it in netto values for both (with social cost, I know... it's a mess...)
    if (!Number.isFinite(salaryHourly) || (isFreelanceJob ? +salaryHourlyText : salaryHourly) < currentMinimalSalary) {
      const currency = getCurrencyByCountry(region.countryName)
      // we pretend isFreelanceJob is always false here, this is because currentMinimalSalary
      // already has social costs excluded and subtracting them makes no sense here
      // in general the logic here is really 💩 and components needs to be reworked
      const minimalNetWage = toNetHourlyWageText(currentMinimalSalary, false, true, region.countryName)
      // also we need to add back the social costs to salaryHourly but only if it is a freelance job
      // because social costs do not apply to internal jobs (even though they probably should (?), dunno norwegian laws)
      const newSalaryHourly = isFreelanceJob
        ? withSocialCost(currentMinimalSalary, region.countryName)
        : currentMinimalSalary

      toast.info(
        `${currentMinimalSalary} ${currency} is the minimum wage for ${this.isHolidayJob() ? 'Holiday job' : jobType}`
      )
      this.setState({
        salaryHourly: newSalaryHourly,
        salaryHourlyText: minimalNetWage,
        salaryCurrency: getCurrencyByCountry(region.countryName),
      })
      // Otherwise we just update the currency in case the region changed.
      // Handling region change + minimal wage is handled already in condition above
    } else {
      this.setState({
        salaryHourly,
        salaryCurrency: getCurrencyByCountry(region.countryName),
      })
    }
  }

  onTipsPickupDayChange = (value: string) => () => {
    this.setState(({ tipsPickupDay }) => ({
      tipsPickupDay: tipsPickupDay === value ? '' : value,
    }))
  }

  isJobOptionSelected = (optionId: string): boolean => {
    const { jobOptions } = this.state
    return jobOptions.indexOf(optionId) >= 0
  }

  onToggleJobOption = (optionId: string) => () => {
    if (this.isJobOptionSelected(optionId)) {
      this.setState((state) => ({
        jobOptions: state.jobOptions.filter((id) => id !== optionId),
      }))
    } else {
      this.setState((state) => ({
        jobOptions: [...state.jobOptions, optionId],
      }))
    }
  }

  onDescriptionChange = (description: ChangeEvent<HTMLInputElement>) => {
    const descriptionValue = description.target.value
    this.setState({ description: descriptionValue })
  }

  onMessageChange = (message: ChangeEvent<HTMLInputElement>) => {
    this.setState({ informationMessage: message.target.value })
  }

  getPoolData = (): { isPublic: boolean; publicSince?: Date } => {
    const { poolAccess, publicSince } = this.state
    return (
      {
        PRIVATE: {
          isPublic: false,
        },
        PUBLIC: {
          isPublic: true,
        },
        PUBLIC_SINCE: {
          isPublic: false,
          publicSince,
        },
      }[poolAccess] || { isPublic: false }
    )
  }

  isLowerThanRecommended = () => {
    const { salaryHourlyText, isFreelanceJob, region, shifts } = this.state
    const { constants } = this.props
    const isSomeShiftToday = shifts.some((shift) => moment(shift.timeStart.toDate()).isSame(moment().toDate(), 'day'))
    // must be lower than recommended (220), must be event job, must be in Norway, must be on same day (last-minute)
    // if any of these conditions is not met, we don't display the dialog
    return (
      !!(isSomeShiftToday && isFreelanceJob && region.countryName === 'Norway') &&
      +salaryHourlyText < (constants?.recommended ?? 220)
    )
  }

  onPressSaveJob = async () => {
    const { business, businessId, history, isAdmin, job, jobId, manager, onSave, perf, internalToExternalActivityId } =
      this.props
    if (!business) {
      return
    }
    const {
      address,
      customUniform,
      description,
      duties: stateDuties,
      employmentReason,
      fulltimeWanted,
      invites,
      isFreelanceJob,
      jobOptions,
      jobType,
      limitedToInvitedUntil,
      location,
      locationDescription,
      locationPlaceId,
      notifyOnlyManager,
      region,
      recommendedWageDialog,
      salaryCurrency,
      salaryHourly,
      selectedBusiness,
      shifts,
      tipsPickupDay,
      uniform,
      useCustomAddress,
      usePermanentContractWage,
      isInformationMessage,
      informationMessage,
      useCustomLabel,
      customLabel,
      shiftsToUpdate,
      oldWage,
      oldAddress,
    } = this.state

    // If the user chosen to dismiss the wage dialog (post despite low wage), we don't show it again
    if (recommendedWageDialog !== 'dismissed') {
      if (this.isLowerThanRecommended()) {
        this.setState({
          recommendedWageDialog: 'open',
        })
        return
      }
    }

    const activeDuties: string[] = stateDuties.reduce((result: string[], duty) => {
      const [dutyName, isEnabled] = R.toPairs(duty)[0]
      return isEnabled && dutyName.length > 0 ? [...result, dutyName] : result
    }, [])
    const allNewDuties: string[] = stateDuties.reduce((result: string[], duty) => {
      const [dutyName] = R.toPairs(duty)[0]
      return dutyName.length > 0 ? [...result, dutyName] : result
    }, [])
    const isHolidayJob = this.isHolidayJob()
    const poolData = this.getPoolData()

    if (!jobType) {
      errorToast('You must select a type of the job before posting one.')
      throw Error('No jobType selected.')
    }

    if (isInformationMessage && !informationMessage) {
      errorToast('Information message field is empty.')
      throw Error('Information message field is empty.')
    }

    this.setState({
      saveInProgress: true,
    })
    if (!jobId) {
      let newJobId
      const trace = perf.trace('Job Post (function time)')
      trace.start()
      try {
        if (typeof poolData === 'string') {
          throw Error(poolData)
        }
        const response = await StaffersJobsAPI.jobPost(
          {
            allNewDuties,
            useCustomAddress,
            address: useCustomAddress ? address : business.address,
            placeId: useCustomAddress ? locationPlaceId : '',
            location: useCustomAddress ? locationDescription : '',
            ...(useCustomAddress && location ? { coordinates: location } : {}),
            options: jobOptions,
            description,
            duties: activeDuties,
            employmentReason,
            invites: invites.map(({ id }) => id),
            isFreelanceJob,
            isHolidayJob,
            isTestJob: !!business.isTestUser,
            jobType,
            limitedToInvitedUntil: !!limitedToInvitedUntil,
            region,
            salaryCurrency,
            salaryHourly,
            shifts,
            tipsPickupDay,
            fulltimeWanted,
            notifyOnlyManager,
            uniform: uniform === OTHER_OPTION_KEY ? customUniform : uniform,
            usePermanentContractWage,
            informationMessage: isInformationMessage ? informationMessage : '',
            customLabel: useCustomLabel ? customLabel : '',
            ...poolData,
          },
          selectedBusiness?.id
        )
        newJobId = response.data.jobId
        const url = `${webUrlBase}/jobs/${newJobId}/${shifts[0].shiftId}`
        if (manager) {
          logBusinessEvent(selectedBusiness?.id || '', `Manager ${manager.email} posted new job - ${url}`).catch(
            console.warn
          )
        } else {
          logBusinessEvent(selectedBusiness?.id || '', `Superadmin posted new job for this business - ${url}`).catch(
            console.warn
          )
        }
        if (!isAdmin && manager && selectedBusiness?.id !== manager.businessId) {
          await this.switchBusinessAndRedirectToJob(newJobId)
        }
        if (onSave) {
          await onSave(newJobId, false)
        } else {
          jobShiftTagForExternal(shiftsToUpdate)
          await history.push(`/jobs/${newJobId}/${shifts[0].shiftId}`)
        }
        this.props.closeModal()
      } catch (e) {
        console.warn('CreateEditJob: Error when posting the job:', e)
        errorToast((e as Error)?.message ?? e)
        return
      } finally {
        this.setState({
          saveInProgress: false,
        })
        trace.stop()
      }
      toast.success('Job created successfully')
      if (internalToExternalActivityId) {
        const db = firebase.firestore()
        const activityDoc = await getBusinessActivityById(db, businessId, internalToExternalActivityId).get()
        await activityDoc.ref.update({
          popUpAnswered: true,
        })
      }
    } else {
      try {
        if (typeof poolData === 'string') {
          throw Error(poolData)
        }
        if (!job) {
          throw Error('Unable to fetch job data')
        }
        await StaffersJobsAPI.jobEdit(
          jobId,
          {
            allNewDuties,
            address: useCustomAddress ? address : business.address,
            placeId: useCustomAddress ? locationPlaceId : '',
            location: useCustomAddress ? locationDescription : '',
            ...(useCustomAddress && location ? { coordinates: location } : {}),
            useCustomAddress,
            options: jobOptions,
            description,
            duties: activeDuties,
            employmentReason,
            invites: (invites || [])
              .filter(({ id }) => !job.staffersConfirmed.includes(id))
              .map(({ id }): string => id),
            limitedToInvitedUntil:
              limitedToInvitedUntil && limitedToInvitedUntil !== true
                ? moment(limitedToInvitedUntil).toDate()
                : !!limitedToInvitedUntil,
            isHolidayJob,
            jobType,
            region,
            salaryCurrency,
            salaryHourly,
            shifts,
            tipsPickupDay,
            fulltimeWanted,
            notifyOnlyManager: typeof notifyOnlyManager === 'string' ? [notifyOnlyManager] : notifyOnlyManager || null,
            uniform: uniform === OTHER_OPTION_KEY ? customUniform : uniform,
            usePermanentContractWage,
            informationMessage: isInformationMessage ? informationMessage : '',
            customLabel: useCustomLabel ? customLabel : '',
            ...poolData,
            oldWage,
            oldAddress,
          },
          selectedBusiness?.id
        )

        const url = `${webUrlBase}/jobs/${jobId}/${shifts[0].shiftId}`
        if (manager) {
          logBusinessEvent(manager.businessId || '', `Manager ${manager.email} edited job - ${url}`).catch(console.warn)
        } else {
          logBusinessEvent(businessId, `Superadmin edited job posted by this business - ${url}`).catch(console.warn)
        }
        if (!isAdmin && manager && selectedBusiness?.id !== manager.businessId) {
          await this.switchBusinessAndRedirectToJob(jobId, shifts[0].shiftId)
        }
        this.props.closeModal()
      } catch (e) {
        console.warn('CreateEditJob: Error when posting the job:', e)
        errorToast((e as Error)?.message ?? 'Job posting failed')
        this.setState({
          // we always turn off invite modal back in case of an error for user to be able
          // to correct it straight away
          isInviteModalOpen: false,
        })
        return
      } finally {
        this.setState({
          isInviteModalOpen: false,
          saveInProgress: false,
        })
      }
      toast.success('Job edited successfully')
    }
  }

  onPressAddShift = () => {
    this.setState({
      shiftToEdit: null,
    })
  }

  onPressEditShift = (shift: ShiftType, shiftIndex: number) => () => {
    this.setState({
      shiftIndex,
      shiftToEdit: {
        importedFromPlanday: shift.importedFromPlanday,
        shiftId: shift.shiftId,
        breakDuration: shift.breakDuration,
        positions: shift.positions,
        timeStart: shift.timeStart,
        timeEnd: shift.timeEnd,
        uniform: shift.uniform,
      },
    })
  }

  onAddShift = async (shift: ShiftTypeBasic) => {
    this.doChangeShifts((shifts) => {
      shifts.push({
        ...shift,
        staffersConfirmed: [],
        staffersPending: [],
        shiftId: getShiftId(shift),
      })
      return shifts
    })
  }

  onAddImportedShift = (jobId: string, shiftId: string) => {
    const { shiftsToUpdate } = this.state
    const newShiftsToUpdate = {
      ...shiftsToUpdate,
      [jobId]: [...(shiftsToUpdate[jobId] || []), shiftId],
    }
    this.setState((prevState) => ({
      importedShifts: [...prevState.importedShifts, shiftId],
      shiftsToUpdate: newShiftsToUpdate,
    }))
  }

  onSeeMore = async () => {
    const { integrationsCtx } = this.props
    const { shiftsLimit, internalShifts, next } = this.state
    if (next) {
      try {
        this.setState({ saveInProgress: true })
        const { results: shifts, next: newNext } = await integrationsCtx.get(next)
        const newPlandayShifts = transformPlandayShifts(Object.values(shifts) || [])
        this.setState({
          shiftsLimit: shiftsLimit + 5,
          internalShifts: [...(internalShifts || []), ...(newPlandayShifts || [])],
          next: newNext || '',
        })
      } catch (e) {
        errorToast('Failed to load more shifts')
      } finally {
        this.setState({
          saveInProgress: false,
        })
      }
    }
  }

  onDuplicateShift = async (shift: ShiftTypeBasic) =>
    this.doChangeShifts((shifts) => {
      const times = {
        timeStart: timeStampFromDate(moment(shift.timeStart).add(1, 'd').toDate()),
        timeEnd: timeStampFromDate(moment(shift.timeEnd).add(1, 'd').toDate()),
      }
      const shiftWithoutPlandayId = R.dissoc('importedFromPlanday', shift)
      shifts.push({
        ...shiftWithoutPlandayId,
        ...times,
        staffersConfirmed: [],
        staffersPending: [],
        shiftId: getShiftId(times),
      })
      return shifts
    })

  onEditShift = (index: number) => async (shift: ShiftTypeBasic) =>
    this.doChangeShifts((shifts) => {
      shifts.splice(index, 1, {
        ...shift,
        staffersConfirmed: shifts[index].staffersConfirmed,
        staffersPending: shifts[index].staffersPending,
        shiftId: getShiftId(shift),
      })
      return shifts
    })

  removeShift = async (index: number, shift: ShiftType & { jobId?: string }) => {
    const { shiftsToUpdate } = this.state
    this.doChangeShifts((shifts) => {
      shifts.splice(index, 1)
      return shifts
    })
    if (shift.jobId && shiftsToUpdate[shift.jobId]) {
      const newShiftsToUpdate = {
        ...shiftsToUpdate,
        [shift.jobId]: shiftsToUpdate[shift.jobId].filter((shiftToUpdate) => shiftToUpdate !== shift.shiftId),
      }
      this.setState((prevState) => ({
        importedShifts: prevState.importedShifts.filter((id) => id !== shift.shiftId),
        shiftsToUpdate: newShiftsToUpdate,
      }))
    }
  }

  doChangeShifts = async (updateFunction: (shifts: ShiftType[]) => ShiftType[]) => {
    const updatedShifts = updateFunction(this.state.shifts) // adds or replaces shift

    // Merge duplicate shifts into a single one
    const shifts: Array<ShiftType> = []
    updatedShifts.forEach((updatedShift) => {
      const duplicateShift = shifts.find((shift) => isDuplicateShifts(shift, updatedShift))
      if (duplicateShift) {
        duplicateShift.positions += updatedShift.positions
      } else {
        shifts.push(updatedShift)
      }
    })

    // Update our state and call the callback
    this.setState(
      (prevState) => ({
        // Sort shifts by starting time (ascending)
        shifts: R.sort(
          (shiftA: ShiftType, shiftB: ShiftType): number =>
            moment(shiftA.timeStart).valueOf() - moment(shiftB.timeStart).valueOf(),
          shifts
        ),
        shiftToEdit: undefined,
        shiftIndex: undefined,
        // update publicSince-check after changing shifts
        publicSinceError: prevState.publicSince ? checkPublicSince(prevState.publicSince, shifts) : '',
      }),
      () => {
        this.reAdjustMinSalary()
      }
    )
  }

  closeShiftEdit = () => {
    this.setState({
      shiftToEdit: undefined,
      shiftIndex: undefined,
    })
  }

  // returns always false if job is not freelance (public) job
  isHolidayJob = (): boolean => {
    const { shifts, isFreelanceJob, poolAccess } = this.state
    // We only enforce holiday rules for public jobs (and jobs that will eventually become public)
    return (
      poolAccess !== 'PRIVATE' &&
      isFreelanceJob &&
      shifts.length > 0 &&
      shifts.every((shift) => isHoliday(shift.timeStart) || isHoliday(shift.timeEnd))
    )
  }
}

export default withRouter(
  startTracking(
    connectFirestore(
      (db: Firestore, props: Props, uid: string) => ({
        business: getBusinessById(db, props.businessId),
        pool: props.pool ?? getPoolById(db, props.businessId),
        // Legacy fallback - superAdmin
        manager: props.manager || getManagerById(db, props.managerId || uid),
        constants: getFreelanceJobFees(db),
        jobSkills: getJobSkills(db),
      }),
      connectFirestore(
        (db: Firestore, props: Props) => {
          const { businessId, business, isAdmin, jobId, pool, manager } = props
          if (business) {
            if (business.groupId && pool !== undefined && manager !== undefined) {
              const connectedPoolsIds = pool ? pool.connectedBusinesses.map((id) => id) : []
              return {
                connectedPools: getConnectedPools(db, businessId),
                group: getGroupById(db, business.groupId),
                // If the user is a superadmin, they can access any group business either way
                managerGroupBusinesses: isAdmin
                  ? getBusinessesByGroupId(db, business.groupId)
                  : ((manager && manager.groupBusinesses) || []).map((groupBusinessId) =>
                      getBusinessById(db, groupBusinessId)
                    ),
                customPositions: getGroupJobTypesById(db, business.groupId),
                groupStaffersPermissions: getGroupStaffersPermissions(db, business.groupId),
                invitedStaffersByPools: [...connectedPoolsIds, businessId].map((poolId) =>
                  getPendingInvitedStaffersByPoolId(db, poolId)
                ),
                previousDuties: getPreviousDuties(db, businessId),
                jobStaffers: jobId ? getStaffersByJobId(db, jobId) : null,
              }
            }
          }
          return {
            connectedPools: getConnectedPools(db, businessId),
            jobStaffers: jobId ? getStaffersByJobId(db, jobId) : null,
          }
        },
        (props: Props) => {
          if (!props.business) {
            return (
              <Box m={4} display="flex" justifyContent="center">
                <BeatLoader />
              </Box>
            )
          }
          return <CreateEditJob {...props} />
        }
      )
    ),
    'Post Job (render time)'
  )
)
